
import OBJ      from    "@/assets/modules/OBJMethodsClass"
import DT       from    "@/assets/modules/DateTime"
import URL      from    "@/assets/modules/URLMethodsClass"
export default class dateTime {

constructor(App) {
    this.App            =   App

    this.OBJ            =   new OBJ(this.App)
    this.DT             =   new DT(this.App)
    this.URL            =   new URL(this.App) 

    this.headHeight     =   70
    this.slidersHeight  =   320
}    

getTypeUser =   ()  =>  {

    if(this.App.OBJ.count(this.App.$store.state.oCurrentIP)===0)    return 'other'

    let oCurrentIP = this.App.$store.state.oCurrentIP

    let isAdmin = false
    for(let oIP of oCurrentIP.oAdminsIP)    
        if(oCurrentIP['current-ip'].trim()===oIP['admin-IP'].trim())    {
            isAdmin = !isAdmin
            break
        }

    let isTester = false
    if(!isAdmin)    {
        
        for(let oIP of oCurrentIP.oTestersIP)        
            if(oCurrentIP['current-ip'].trim()===oIP['tester-IP'].trim())    {
                isTester = !isTester
                break
            }
    }

    if(isAdmin)     return 'admin'
    if(isTester)    return 'tester'

    return 'other'
}

imitHeavenlyGate    =   (oSet=[], typeUser='other')  =>  {

    console.log(typeUser)
    console.log(oSet)

    if(typeUser.trim()==='admin')       return oSet['heavenly-gate'].developer
    if(typeUser.trim()==='tester')      return oSet['heavenly-gate'].tester

    return ''
}

getIsExcluded   =   (oSet=[])  =>  {

    if(this.App.OBJ.count(oSet)===0)    oSet = this.App.$store.state.oSet

    if(this.App.OBJ.count(oSet)===0)    return 1

    let netHeavenlyGate = this.URL.getParameterFromURL('net-heavenly-gate')

    if(typeof localStorage.netHeavenlyGate === 'undefined') localStorage.netHeavenlyGate = ''
    if(localStorage.netHeavenlyGate.trim().length === 0)     
        if(netHeavenlyGate.trim().length>0)
            localStorage.netHeavenlyGate = netHeavenlyGate
        else
            localStorage.netHeavenlyGate = this.imitHeavenlyGate(oSet, this.getTypeUser())

    let isExcluded = 0
    let aIP, isExcept = false

    for(let excluded of oSet.excluded)    {

        if(localStorage.netHeavenlyGate.trim()===excluded['serial-no'].trim())  {

            aIP = excluded['except-ip'].split('|')
            for(let ip of aIP)  {
                if(ip.trim() === this.App.$store.state.oCurrentIP['current-ip'].trim())   {
                    isExcept = !isExcept
                    break
                }
            }
            if(!isExcept)   isExcluded = 1
            break
        }
    }    
    return isExcluded
}

viewKeyAndValuesFromObject = (oParam)  =>  {
    
    if(typeof oParam !== 'object') return 'string :: ' + oParam

    let oRecord
    let isIterable = this.isIterable(oParam)
    let inKey = ''

    if(isIterable) {

        for(oRecord of oParam) {
            for(let [key, value] of Object.entries(oRecord)) 
                inKey += key + ' ::: ' + value + ' | '
        }
    } else {

        for(let [key, value] of Object.entries(oParam)) 
            inKey += key + ' ::: ' + value + ' | '
    }

    return inKey
}

isIterable =    (oParam)    =>  {

    if(oParam===null) return false
    if(typeof oParam==='undefined') return false

    return typeof oParam[Symbol.iterator] === 'function';
}

isAdmin =   (typeIP='')  =>  {   //

    let oCurrentIP = this.App.$store.state.oCurrentIP

    let objectIP = []
    if(typeIP.toString().trim().length===0 || typeIP.trim() === 'admin-IP') {
        typeIP      =   'admin-IP'
        objectIP    =   'oAdminsIP'
    }

    if(typeIP.toString().trim().length===0 || typeIP.trim() === 'tester-IP') {
        typeIP      =   'tester-IP'
        objectIP    =   'oTestersIP'
    }

    //console.log(typeIP)
    //console.log(objectIP)
    //console.log(oCurrentIP)
    //console.log(localStorage.axiosPath)

    let isRequiredIP = false;
    for(let ip of oCurrentIP[objectIP]) {
        //console.log(ip[typeIP])
        if(ip[typeIP].trim()===oCurrentIP['current-ip'])   {
            isRequiredIP = !isRequiredIP
            break
        }
    } 

    return isRequiredIP
}

getHeightContent    =   (isSliders=true)    =>  {

    let height  =   ''
    height      =   (screen.height-this.headHeight)

    if(isSliders)   
        height  -=   this.slidersHeight
        
    return  height
}

screenSetup =   (viewAdvertising)   =>      {
    
    this.App.viewAdvertising=viewAdvertising

    this.App.heightContent = this.getHeightContent(viewAdvertising).toString() + 'px',
    this.App.marginTopMsgOff=(((this.getHeightContent(viewAdvertising)-140))/2).toString() + 'px',
    this.App.heightMsgOff='70px'                
}

restartApp  =   ()  =>  {

    let URL = ''

    if(parseInt(localStorage.isLocalhost)===1 || typeof localStorage.isLocalhost==='undefined')    {
        
        let aURL = window.location.href.split('/')
        URL = aURL[0] + '/' + aURL[1] + '/' + aURL[2]
    
    } else {
            
            let restartURL = localStorage.restartURL 
            if(restartURL.trim().length>0) {

                URL = localStorage.restartURL

            } else {

                let aURL = window.location.href.split('/')
                URL = aURL[0] + '/' + aURL[1] + '/' + aURL[2]
            }
        }    

    URL += '/?&' + localStorage.gate + '=' + localStorage.netHeavenlyGate
    URL += '&' + localStorage.net + '=' + localStorage.projectName
    URL += '&timestamp=' + new Date()

    localStorage.startApp = 1
    //console.log(URL)

    setTimeout(() => {window.location.href = URL}, 0);
}

set =   (param, delay=0) =>  {

    //console.log('DELAY :: ' + delay)
    //console.log('URL :: ' + param)

    //if(this.isUnactive(param))  return 

    if(delay===0)
        this.App.$router.push('/'+param)    
    else
        setTimeout(() => {
            this.App.$router.push('/'+param)    
        }, delay);    
}

isActive  =   (param)  =>  {

    if(typeof param === 'undefined') return false

    return parseInt(this.App.oCurrentCharger['chg_blocked' + this.capitalizeFirstLetter(param)]) === 0
}

capitalizeFirstLetter   =   (string)    =>  {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

getCurrentLocale    =   ()  =>  {

    return  localStorage.currentLocale 
}


/*
getPathForFlags     =   ()  =>  {
    console.log('getPathForFlags')
    
    return localStorage.URLFlags
}


getPathForSlider    =   ()  =>  {
    console.log('getPathForSlider')
    //return  localStorage.dataPath
    return ''
}
*/
getPathForSVGFlags     =   ()  =>  {

    //return localStorage.dataPath + localStorage.flagsSVGPath
    return localStorage.URLFlags
}



comeToMenu     =   ()  =>  {
    //history.back()
    this.App.$router.push('/menu')
}

capitalize     =    (param='')  =>  {
    let returnString = param.charAt(0).toUpperCase() + param.substring(1, param.trim().length).toLowerCase()
    return returnString
}

computeHeightScreenApp   =   (difference=0, isSlider=true, notSlider=2)  =>  {

    //let slider = isSlider ? 322: 2

    if(this.OBJ.count(this.App.$store.state.oSliders)===0) isSlider = false
    
    let slider = isSlider ? 322: notSlider
    
    let height = (screen.height - (slider+50))

    if(navigator.userAgent.indexOf('OPR/')>(-1))  
        height -= difference

    return height + 'px'
}

computeMarginTopMessage =   (difference=0, plus=0)  =>  {

    let height

    height = (screen.height - (322+50))

    if(navigator.userAgent.indexOf('OPR/')>(-1))  
        height -= difference

    let returnValue = (((height-150)/2)+plus)

    if(this.OBJ.count(this.App.$store.state.oSliders)===0) 
        returnValue += 80

    return  returnValue + 'px'
}

computeHeightMap    =   (difference=0, minus=0) =>  {
    let height = (screen.height - (322+50))

    if(navigator.userAgent.indexOf('OPR/')>(-1))  
        height -= difference

    return (height-minus) + 'px'
}

}
export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["francúzština"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr"])},
  "helper": {
    "do-you-need-advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous besoin de conseils ?"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chargeur n'est pas actif ou est en cours de maintenance"])},
  "is-excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application est actuellement désactivée par l'administrateur"])},
  "async-read-data-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez patienter pendant que l'application charge les données : elle met à jour son magasin de données."])},
  "async-read-data-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est recommandé de parcourir également l'intégralité de l'application pour vérifier sa fonctionnalité,"])},
  "async-read-data-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ainsi que parce que le contenu complet de l'application, y compris les écrans, est chargé dans l'entrepôt de données."])},
  "async-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je recherche un chargeur pour mon vélo électrique - au moins 3 caractères"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application peut vous trouver un module de recharge compatible avec la marque de votre machine"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application commence à rechercher un module de charge compatible seulement après avoir saisi au moins trois caractères"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La machine électrique que vous recherchez est actuellement introuvable dans le catalogue d'applications"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'informations sur la page WEB"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les prévisions météo ne sont pas disponibles actuellement !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prévisions météo horaires"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction du vent"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La pluie"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse du vent"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafales de vent"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pression de l'air"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il neige"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lever du soleil"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coucher de soleil"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ensoleillé"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à moitié clair"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuageux"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["presque nuageux"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complètement nuageux"])}
  },
  "no-acitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chargeur est inactif ou en cours de maintenance"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOS"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARTE"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LANGUES"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LA MÉTÉO"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO RÉGION"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONNECTEUR"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIDER"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu des applications"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette partie de l'application est momentanément indisponible"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chargeur ne prend actuellement pas en charge le Wi-Fi partagé"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour son fonctionnement, l'application nécessite un affichage avec une résolution minimum : largeur 800 et hauteur 600 pixels"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janvier"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Février"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peut"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juin"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juillet"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Août"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septembre"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octobre"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décembre"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le propriétaire du chargeur a décidé de ne pas partager le réseau WIFI public pour des raisons techniques"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un réseau WIFI partagé est disponible à proximité du chargeur"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, nous préparons le contenu."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes désolés,~mais la carte d'orientation de ce chargeur n'est pas disponible pour le moment"])}
  }
}
export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nemčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "helper": {
    "do-you-need-advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brauchen Sie Rat ?"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ladegerät ist inaktiv oder wird gewartet"])},
  "is-excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anwendung ist derzeit vom Administrator deaktiviert"])},
  "async-read-data-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warten Sie, während die Anwendung die Daten lädt – sie aktualisiert ihren Datenspeicher."])},
  "async-read-data-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wird empfohlen, die gesamte Anwendung durchzugehen, um auch deren Funktionalität zu überprüfen,"])},
  "async-read-data-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sowie aus dem Grund, dass der komplette Inhalt der Anwendung inklusive der Bildschirme in das Data Warehouse geladen wird."])},
  "async-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich suche ein Ladegerät für mein Elektrofahrrad – mindestens 3 Zeichen"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anwendung kann ein Lademodul finden, das mit der Marke Ihres Geräts kompatibel ist"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erst nach der Eingabe von mindestens drei Zeichen beginnt die Anwendung mit der Suche nach einem kompatiblen Lademodul"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die von Ihnen gesuchte elektrische Maschine ist derzeit nicht im Anwendungskatalog zu finden"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen auf der WEB-Seite"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Wettervorhersage ist derzeit nicht verfügbar !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stündliche Wettervorhersage"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windrichtung"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Regen"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windgeschwindigkeit"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windstöße"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftdruck"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es schneit"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sonnenaufgang"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sonnenuntergang"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sonnig"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halb klar"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wolkig"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fast bewölkt"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["völlig bewölkt"])}
  },
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZURÜCK"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KARTE"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPRACHEN"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAS WETTER"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGION"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERBINDER"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HILFE"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendungsmenü"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Teil der Anwendung ist vorübergehend nicht verfügbar"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ladegerät unterstützt derzeit kein Shared WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anwendung benötigt für ihre Funktionalität ein Display mit einer Mindestauflösung: Breite 800 und Höhe 600 Pixel"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["März"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezember"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Besitzer des Ladegeräts hat sich aus technischen Gründen entschieden, das öffentliche WLAN-Netz nicht zu teilen"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Nähe des Ladegeräts steht ein gemeinsam genutztes WLAN-Netzwerk zur Verfügung"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entschuldigung, wir bereiten den Inhalt vor."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es tut uns leid,~aber die Orientierungskarte für dieses Ladegerät ist derzeit nicht verfügbar"])}
  }
}

import  axios               from    "axios"
import  SPEC                from    "@/assets/modules/Specials"
import  DT                  from    "@/assets/modules/DateTime"
import  OBJ                  from    "@/assets/modules/OBJMethodsClass"

import  setApp              from    '@/App.json'

export default class dbProceduresFromApp {

constructor(App) {

    this.App        =   App
    this.OBJ       =    new OBJ(this.App)
    this.SPEC       =   new SPEC(this.App)
    this.DT         =   new DT(this.App)

    this.script     =   'dbProc.App.'
}    

dbg   =   (   content='', scriptName='dbProc.js' )  =>  {


    if(typeof localStorage.netHeavenlyGate === 'undefined') {
        console.error('V tejto chvíli nepoznám kľúč od brány !')
        return
    }

    if(localStorage.netHeavenlyGate.trim().length === 0) {
        console.error('V tejto chvíli nepoznám kľúč od brány !')
        return
    }    


    let app = 'REMOTE POINT'
   
    let scriptPath = ''
    if(typeof this.App.scriptPath === 'string') 
        scriptPath = this.App.scriptPath

    if(typeof content === 'number') content = content.toString()
    if(typeof content === 'object') content = JSON.stringify(content)

    let login = ''
    if(this.OBJ.count(this.App.$store.state.oCurrentCharger)>0)
        login = this.App.$store.state.oCurrentCharger.chg_serialNo

    if(content.trim().length===0)   return

    let from = scriptPath + scriptName + ' ::: dbg'
    let aFormData   =   {}
    aFormData['axios_'] =   from
    aFormData['content'] =   content
    let fields  =   '{'
        fields  +=  '"from":"'+from.split(':::')+'", '
        fields  +=  '"app":"'+app+'", '
        fields  +=  '"login":"'+login+'"}'
    this.setAxios(aFormData, fields)

    //console.log(fields)

    //console.log(content)
    //console.log(fields)
}

sendCentralServerApp    =   (netHeavenlyGate, isLocalhost)  =>  {

    let centralServerApp = window.location.href.split('/')[2]
    //console.log(centralServerApp)
    //console.log(netHeavenlyGate)

    let formData = new FormData()
    formData.append('command', 'proc-sendCentralServerApp')   
    formData.append('netHeavenlyGate', netHeavenlyGate)
    formData.append('centralServerApp', centralServerApp)
    formData.append('isLocalhost', isLocalhost)
    formData.append('other-protocol', setApp['other-protocol'])
    axios.post(localStorage.restAPI, formData).then(response => {
        console.log(response.data)
        
        if(typeof response.data === 'object')
            this.App['oNetHeavenlyGate'] = response.data
    })    

}

controlNetHeavenlyGate  =   (netHeavenlyGate='')  =>  {

    let formData = new FormData()
    formData.append('command', 'proc-controlNetHeavenlyGate')   
    formData.append('netHeavenlyGate', netHeavenlyGate)
    formData.append('other-protocol', setApp['other-protocol'])
    axios.post(localStorage.restAPI, formData).then(response => {
        //console.log(response.data)
        if(typeof response.data === 'object')
            this.App['oNetHeavenlyGate'] = response.data
    })
}

getBasicSecurity = ()   =>  {

    let formData = new FormData()
    formData.append('command', 'proc-getBasicSecurity')
    formData.append('other-protocol', setApp['other-protocol'])
    axios.post(localStorage.restAPI, formData).then(response => {
        if(typeof response.data === 'object')
            this.App['oBasicSecurity'] = response.data
    })
}

getRestartURL   =   ()  =>  {

    let formData = new FormData()
    formData.append('command', 'proc-getRestartURL')
    formData.append('other-protocol', setApp['other-protocol'])
    formData.append('serialNo',       localStorage.netHeavenlyGate)
    //console.log(localStorage.restAPI)
    axios.post(localStorage.restAPI, formData).then(response => {
        //console.log(response.data)
        if(typeof response.data === 'object')
            this.App['oRestartURL'] = response.data
    })
}

getProjectSetup =   ()  =>  {

    let formData = new FormData()
    formData.append('command', 'proc-getProjectSetup')
    formData.append('other-protocol', setApp['other-protocol'])
    axios.post(localStorage.restAPI, formData).then(response => {
        //console.log(response.data)
        if(typeof response.data === 'object')
            this.App['oSet'] = response.data
    })
}

getCurrentIP    =   ()  =>  {

    let formData = new FormData()
    formData.append('command', 'proc-getCurrentIP')
    axios.post(localStorage.restAPI, formData).then(response => {
        if(typeof response.data === 'object')   {
            //console.log(response.data)
            this.App['oCurrentIP'] = response.data
        }
    })    
}

connectRemotePoint   =   (scriptName)  =>  {

    let aFormData   =   {}
    aFormData['axios_']         =   this.App.scriptPath + scriptName + ' ::: connectRemotePoint'
    aFormData['dateTime']       =   this.DT.stdFromJSDateTime(new Date(), true, true)
    aFormData['useAPI']         =   localStorage.useAPI
    aFormData['other-protocol'] =   setApp['other-protocol']
    this.setAxios(aFormData, '', 'oChargers')        
}

readChargersForDebugger =   (scriptName='dbProc.js')  =>  {

    let aFormData   =   {}
    aFormData['axios_']         =   this.App.scriptPath + scriptName + ' ::: readChargersForDebugger'
    aFormData['other-protocol'] =   setApp['other-protocol']

    let fields  =   '{"serialNo":"'+localStorage.netHeavenlyGate+'"}'
    console.log(fields)

    this.setAxios(aFormData, fields, 'oChargers')    
}


flagsFromCurrentCharger = (scriptName='dbProc.js')  =>  {     //methodName, appVariable, fields

    let aFormData   =   {}
    //aFormData['axios_']         =   this.App.scriptPath + scriptName + ' ::: readChargersForDebugger'
    aFormData['axios_']         =   this.App.scriptPath + scriptName + ' ::: flagsFromCurrentCharger'
    aFormData['exception']      =   '401q7OVXI9J4BQKDteL5stO9XsIK23'    //???
    //aFormData['other-protocol'] =   setApp['other-protocol']

    let fields = '{"serialNo":"'+localStorage.netHeavenlyGate+'"}'
    console.log(fields)
    this.setAxios(aFormData, fields, 'oFlags')        
}

getPromoVideo   =   (scriptName)  =>  {     //methodName, appVariable, fields

    let aFormData           =   {}
    aFormData['axios_']     =   this.App.scriptPath + scriptName + ' ::: getPromoVideo'
    let fields              =  '{"serialNo":"'+localStorage.netHeavenlyGate+'"}'
    this.setAxios(aFormData, fields, 'oPromoVideo')
}


getSlidersActiveOnly  =   (scriptName='dbProc.js')  =>  {   

    let aFormData           =   {}
    aFormData['axios_']     =   this.App.scriptPath + scriptName + ' ::: getSlidersActiveOnly'
    let fields  =   '{"chargerId":"'+this.App.$store.state.oCurrentCharger.chg_id+'", '
        fields  +=  '"serialNo":"'+this.App.$store.state.oCurrentCharger.chg_serialNo+'"}'

    this.setAxios(aFormData, fields, 'oSliders')
}

callProcessViaAxios =   (axios_='', formData, appVariable='', viewVars=false) =>  {
    //console.log('callProcessViaAxios')
    //let oJSON

    if(viewVars)    console.log(localStorage.restAPI)

    var App = this.App
    //console.log(localStorage.restAPI)
    axios.post(localStorage.restAPI, formData).then(response => {

        //console.log(axios_)
        console.log(response.data)
        //console.log(appVariable)

        if(!this.SPEC.isErrorResponseData(axios_, formData, response, appVariable.trim().length>0, false))  {
            if(appVariable.trim().length>0)    
                this.App[appVariable] = response.data                    
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('dbProc.js', error, formData, axios_)})
}



//-----------------------------------------------------------------------
setAxios =   (aFormData, fields, appVariable='', viewVars=false)  =>  { 

    if(viewVars)  console.log(aFormData.axios_)

    if(viewVars)  console.log(fields)

    let axios_
    let isAxios = false, oParams = {}
    for (const [key, value] of Object.entries(aFormData)) {

        if(key.trim().toLowerCase()==='axios_') {
            axios_  =   value
            isAxios =   true

        } else oParams[key] = value
    }    

    if(!isAxios)    {
        if(viewVars)  console.log('AXIOS NOT FOUND !')
        return
    }
    
    let formData = this.SPEC.formData(axios_)
    let command = setApp.proccess + '-' + axios_.split(':::')[1].trim()
    formData.append('command', command)
    formData.append('axios_', aFormData.axios_)
    

    if(viewVars)  console.log('FORM START ---')
    if(viewVars)  console.log('COMMAND :: ' + command)
    if(viewVars)  console.log('FIELDS :: ' + fields)

    formData.append('fields', fields)
    for (const [key, value] of Object.entries(oParams))    {
        
        if(viewVars)  console.log('ADD :: ' + key + ' ::: ' + value)
        formData.append(key, value)
    }
    if(viewVars)  console.log('FORM END ---')
    this.callProcessViaAxios(axios_, formData, appVariable, viewVars)
}

}


export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ruština"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ru"])},
  "helper": {
    "do-you-need-advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам нужен совет ?"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарядное устройство не работает или проходит обслуживание"])},
  "async-read-data-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подождите, пока приложение загрузит данные, — обновите его хранилище данных."])},
  "async-read-data-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуется пройти все приложение, а также проверить его работоспособность,"])},
  "async-read-data-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["а также по той причине, что в хранилище данных загружается весь контент приложения, включая экраны."])},
  "async-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ищу зарядное устройство для своего электровелосипеда - минимум 3 символа"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение сможет найти вам зарядный модуль, совместимый с маркой вашей машины"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение начинает поиск совместимого зарядного модуля только после ввода не менее трёх символов"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрическая машина, которую вы ищете, в настоящее время не найдена в каталоге приложений"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Более подробная информация на веб-странице"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогноз погоды в настоящее время недоступен !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почасовой прогноз погоды"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление ветра"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дождь"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скорость ветра"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порывы ветра"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Давление воздуха"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идет снег"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bосход"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["закат"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cолнечно"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["наполовину ясно"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["облачно"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["почти облачно"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["полностью облачно"])}
  },
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НАЗАД"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КАРТА"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЯЗЫКИ"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОГОДА"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНФО РЕГИОН"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СОЕДИНИТЕЛЬ"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОМОЩЬ"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меню приложения"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта часть приложения временно недоступна"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарядное устройство в настоящее время не поддерживает общий WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для своей функциональности приложению требуется дисплей с минимальным разрешением: ширина 800 и высота 600 пикселей"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воскресенье"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понедельник"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вторник"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среда"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четверг"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пятница"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суббота"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Январь"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Февраль"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mарта"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aпрель"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mай"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Июнь"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Июль"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aвгуст"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cентябрь"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oктябрь"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hоябрь"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Декабрь"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец зарядного устройства решил не делиться общедоступной сетью WIFI по техническим причинам"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рядом с зарядным устройством доступна общая сеть WIFI"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизоваться"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извините, мы готовим контент."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К сожалению,~в данный момент карта ориентации для этого зарядного устройства недоступна"])}
  }
}

import  axios       from    "axios"

import  OBJ         from    '@/assets/modules/OBJMethodsClass'
import  LIB         from    '@/assets/modules/Library'
import  DT          from    '@/assets/modules/DateTime'
import  URL         from    '@/assets/modules/URLMethodsClass'
import  SPEC        from    "@/assets/modules/Specials"

export default class dateTime {

constructor (App) {
    this.App    =   App

    this.OBJ    =   new OBJ(this.App)
    this.LIB    =   new LIB(this.App)
    this.DT     =   new DT(this.App)
    this.URL    =   new URL(this.App)
    this.SPEC    =  new SPEC(this.App)

    this.axios = 'assets/modules/Debugger.js'
}

mailForDeveloper    =   ()  =>  {

    let oSet            =   this.App.$store.state.oSet
    let serialNo = this.App.$store.state.oCurrentCharger.chg_serialNo

    if(serialNo.indexOf('x')===5)
        serialNo = serialNo.substr(0,5)
    else
        serialNo = serialNo.substr(0,serialNo.indexOf('@'))
    
    let from    =   serialNo + '-charger@' + oSet.domain
    let to      =   localStorage.developerMail
    let subject =   'Užívateľ vzdialeného bodu (' + serialNo + ') zaslal vývojárovi obsah aktuálnej konzoly'


    let formData = this.SPEC.formData(this.axios)
    formData.append('command',  'proc-sendMail')
    formData.append('body',     this.App.$store.state.contentConsole)    

    let fields  =   '{'
    fields  +=   '"from":"'+from+'",'
    fields  +=   '"to":"'+to+'",'
    fields  +=   '"subject":"'+subject+'"}'    
    formData.append('fields',   fields)

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response=>{
        if(!this.SPEC.isErrorResponseData('Debugger.js (Console)', formData, response, false, false))  {

            alert('Obsah debuggera bol úspešne zaslaný vývojarovi !')
        }
    }).catch(function(error) {new SPEC(App).axiosErrorCatch('Debugger.js (2)', error, formData)})
}

clearCon   =   ()  =>  {

    let eConsoleContent = document.getElementById('console-content') 
    if(eConsoleContent!==null)    {
        eConsoleContent.innerHTML = ''
        this.App.$store.commit('contentConsoleToVuex', '')     
    }
}



con     =   (param='', f='Debugger.js', l=0,  openConsole=false)   =>  {

    if(typeof param === 'object')   param = JSON.stringify(param)
    if(param.trim().length===0) return

    let inner = ''
    let eConsoleContent = document.getElementById('console-content') 
    if(eConsoleContent!==null)    {

        inner = eConsoleContent.innerHTML

        let style   =   'clear:both;'
            style   +=  'float:left;'
            style   +=  'width:100%;'
            style   +=  'border-top:dotted 1px #fff;'
            style   +=  'margin:20px 0 0 0;'

        let span = ''
        if(l>0 || f.trim().length>0)
            span = '<span style="font-size:.7em;color:#ffcfcf;font-weight:0;">'+f+' / riadok: '+l+ ' :: </span><br />'

        let html    =   '<div style="'+style+'">' + span + param + '</div>'

        inner = inner + html

        eConsoleContent.innerHTML = inner 

        let contentConsole  =   this.App.$store.state.contentConsole + '<br />'
            contentConsole  +=  f+' / riadok: '+l+ ' :: <br />'
            contentConsole  +=  param + '<br />' 
            contentConsole  +=  '-------------------------------------------' + '<br />'

        this.App.$store.commit('contentConsoleToVuex', contentConsole)     
    }

    if(openConsole) {
        let eViewConsoleInApp   =   document.getElementById('view-console-in-App')
        if(eViewConsoleInApp!==null)
            if(eViewConsoleInApp.classList.contains('display-none'))
                eViewConsoleInApp.classList.remove('display-none')
    }
}

openDbg =   ()  =>  {

    let viewConsoleInApp    =   document.getElementById('view-console-in-App') 

    if(viewConsoleInApp!==null) 
        if(viewConsoleInApp.classList.contains('display-none')) 
            viewConsoleInApp.classList.remove('display-none')
        else 
            viewConsoleInApp.classList.add('display-none')

}

closeDbg    =   ()  =>  {

    let viewConsoleInApp    =   document.getElementById('view-console-in-App') 
    if(viewConsoleInApp!==null) 
        if(!viewConsoleInApp.classList.contains('display-none')) 
            viewConsoleInApp.classList.add('display-none')
}

readForDebugger =   ()  =>   {

    let formData = this.SPEC.formData(this.axios)
    formData.append('command', 'proc-readContentDebugger')

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        if(!this.SPEC.isErrorResponseData('Debugger.js (1)', formData, response, true, false))  {
            
            this.App.oDBG = response.data
    
            let dbgWorkSpace    = document.getElementById('dbg-work-space')
            if(dbgWorkSpace!==null) {
        
                dbgWorkSpace.innerHTML = ''
                let inner           = '<table border="0" width="100%">'
        
                //console.log(this.App.oDBG)
                for(let oDBGLine of this.App.oDBG)   {

                    if(typeof oDBGLine.theme !== 'string')      oDBGLine.theme  =   ''
                    if(typeof oDBGLine.value !== 'string')      oDBGLine.value  =   ''


                        if(oDBGLine.theme.trim().length>0  && oDBGLine.value.trim().length>0)   {
        
                            inner += '<tr>'
                            inner += '<td style="color:#000;text-align:right;border-bottom:dotted 1px #cc0000;">'
                            inner   +=  oDBGLine.theme.replace(' ', '&nbsp;') + ':&nbsp;'
                            inner += '</td>'
                            inner += '<td style="text-align:left;border-bottom:dotted 1px #cc0000;">'
                            inner   +=  eval(oDBGLine.value)
                            inner += '</td>'                            
                            inner += '</tr>'
                        }
                    
                }
                inner += '</table>'
                dbgWorkSpace.innerHTML = inner
            }            
        }

        
        //let oDBG = response.data

        /*
        if(typeof response.data !== 'object')   return
        if(this.OBJ.count(response.data)===0)   return  
        if(typeof response.data.error === 'string')   {
            console.log(response.data)
            //send mail
            return
        }
        */

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('Debugger.js (1)', error, formData)})
}

refreshDbg  =   ()  =>  {

    this.readForDebugger()
    //this.rerender = new Date() 
}

restartApp  =   (netHeavenlyGate='')  =>  {

    //alert('Restart :: ' + netHeavenlyGate)

    let URL = ''

    if(parseInt(localStorage.isLocalhost)===1 || typeof localStorage.isLocalhost==='undefined')    {

        let aURL = window.location.href.split('/')
        URL = aURL[0] + '/' + aURL[1] + '/' + aURL[2]
    } else {

        let restartURL = localStorage.restartURL 

        if(typeof localStorage.restartURL === 'undefined')
            localStorage.restartURL = ''

        if(restartURL.trim().length>0) {

            URL = localStorage.restartURL

        } else {

            let aURL = window.location.href.split('/')
            URL = aURL[0] + '/' + aURL[1] + '/' + aURL[2]
        }
    }

    if(netHeavenlyGate.trim().length===0)   {

        URL += '/?&' + localStorage.gate + '=' + localStorage.netHeavenlyGate
        URL += '&' + localStorage.net + '=' + localStorage.projectName
    }

    if(netHeavenlyGate.trim().length>0)   {

        URL += '/?&' + localStorage.gate + '=' + netHeavenlyGate
        URL += '&' + localStorage.net + '=' + localStorage.projectName
        localStorage.netHeavenlyGate = netHeavenlyGate
    }

    URL += '&timestamp=' + new Date()

    localStorage.startApp = 1

    setTimeout(() => {window.location.href = URL}, 0);

}

}


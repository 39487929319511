
import axios    from    "axios"
import OBJ      from    '@/assets/modules/OBJMethodsClass'
import LIB      from    '@/assets/modules/Library'
import DT       from    '@/assets/modules/DateTime'
import SPEC     from    '@/assets/modules/Specials'
import PROC     from    '@/assets/modules/dbProc'

export default class AsyncData {

constructor(App) {

    this.App            =   App
    this.PROC           =   new PROC(this.App)
    this.OBJ            =   new OBJ(this.App)
    this.LIB            =   new LIB(this.App)
    this.DT             =   new DT(this.App)
    this.SPEC           =   new SPEC(this.App)
    
    this.countFlags     =   0

    //this.viewResponse   =   true

    this.viewStartFunc  =   false   //  true
    this.viewRestart    =   false   //  true
    this.viewChargers   =   false   //  true
    this.viewFlags      =   false   //  true
    this.viewSliders    =   false   //  true
    this.viewCMS        =   false   //  true

    this.axios = 'assets/modules/AsyncData.js'
}

//===================================================================
chargerDataRead   =   ()  =>  {

    if(this.viewStartFunc)  console.log('START chargerDataRead('+new Date()+')')

    if(typeof this.App.controlInterval === 'undefined') {
        this.App.controlInterval =  setInterval(() => this.chargerDataRead(), localStorage.devControlInt)
    }

    if(typeof this.App.globalInterval === 'undefined') {
        this.App.globalInterval  =  setInterval(() => this.App.comeBackEvents_app(2), localStorage.devGlobalInt)
    }

    //let test = window.location.href.split('/')
    //if(test[3].trim().length > 0 && test[3].indexOf('net-heavenly-gate')===(-1))  return

    if(parseInt(localStorage.startApp)!==1) {
        
        if(localStorage.timeAsyncReadData===this.DT.stdFromJSDateTime(new Date(), true, false)) return
        localStorage.timeAsyncReadData = this.DT.stdFromJSDateTime(new Date(), true, false)
    } else {

        localStorage.startApp = 0
        localStorage.timeAsyncReadData = this.DT.stdFromJSDateTime(new Date(), true, false)
    }

    //console.log('ASYNC :: '+localStorage.timeAsyncReadData)
    //console.log(this.DT.stdFromJSDateTime(new Date(), true, false))

    console.log('A WATCH TOWER : ' + this.DT.timeFromTimeStamp())
    if(this.viewStartFunc)  console.log('WATCH TOWER : ' + this.DT.timeFromTimeStamp())

    let eWatcher = document.getElementById('watcher')
    if(eWatcher!==null) {
        eWatcher.innerHTML = this.DT.timeFromTimeStamp()
    }

    //console.log(localStorage.netHeavenlyGate)
    //console.log(localStorage.useAPI)
    

    let formData = this.SPEC.formData(this.axios)
    //formData.append('command', 'client')  to do remove
    formData.append('command',              'proc-connectRemotePoint')

    let isOK = false
    let netHeavenlyGate 
    if(typeof localStorage.netHeavenlyGate !== 'undefined' )    {

        if(localStorage.netHeavenlyGate.trim().length > 0  )    {

            netHeavenlyGate = localStorage.netHeavenlyGate
            isOK = true
        }
    } 
    
    if(!isOK)   {

        netHeavenlyGate = this.App.oSet['heavenly-gate'].developer
    }

    formData.append('net-heavenly-gate',    netHeavenlyGate)
    formData.append('useAPI',               localStorage.useAPI)
    formData.append('dateTime',             this.DT.stdFromJSDateTime(new Date(), true, true))

    console.log(netHeavenlyGate)

    //formData.append('currentTime',  this.DT.timeFromTimeStamp())
    
    //console.log(this.DT.stdFromJSDateTime(new Date(), true, true))

    let oCharger

    var App = this.App
    //new SPEC(App)`.axios`ErrorCatch('AsyncData.js (1)', 'Toto je simulovaná chyba', formData)

    axios.post(localStorage.restAPI, formData).then(response => {

        if(typeof response.data['serialNo'] === 'string')   
            localStorage.substitutionSerialNo  = response.data['serialNo']

        let requiredObject = true, mustBeGreaterThanZero = true
        if(localStorage.projectName.trim().toLowerCase()==='local')   {
            //requiredObject = false
            //mustBeGreaterThanZero = false
        }
        if(!this.SPEC.isErrorResponseData('AsyncData.js (1)', formData, response, requiredObject, mustBeGreaterThanZero ))  {
            
            //console.log(response.data)
            oCharger    =   response.data
       
            if(this.viewChargers)    {
                console.log('oCharger:')
                console.log(oCharger)
            }

            if(parseInt(oCharger.chg_restartApp)===1) {

                if(this.viewChargers)    console.log('RESTART APP !!!')

                this.startRestartApp( oCharger)
            }
    
            this.App.isActiveApp            =   oCharger.chg_active
    
            localStorage.isDigital          =   oCharger.chg_digitalClock
            localStorage.isTrial            =   oCharger.chg_trial
            localStorage.trialTo            =   oCharger.chg_trialTo
    
            //this.App.$store.commit('oCurrentChargerToVuex', oCharger)
    
            //this.updateLastConnect(oCharger.chg_id)   to do remove
    
            if(this.viewChargers)  {
                console.log('IS UPDATES ? :: '+oCharger.chg_updates)
            }
    
            if(parseInt(oCharger.chg_updates)===0 ) {



                this.countFlags = this.OBJ.count(this.App.$store.state.oFlagsFromThisCharger)

                //this.handWithAnimation(oCharger)
                
            }
            else    {
                //this.updateCharger('updates', 0, oCharger.chg_id)  to do remove
    
                if(this.viewChargers)  {
                    console.log('UPDADES APP :: '+oCharger.chg_id)
                }
            }
            //oCharger['globalInterval']
            if(parseInt(localStorage.devGlobalInt)!==(parseInt(oCharger.chg_globalInterval)*60000))  {
                //oCharger['globalInterval']
                localStorage.devGlobalInt = parseInt(oCharger.chg_globalInterval)*60000
            }
            //oCharger['controlInterval']
            if(parseInt(localStorage.devControlInt)!==(parseInt(oCharger.chg_controlInterval)*60000))  {
                // oCharger['controlInterval']
                localStorage.devControlInt = parseInt(oCharger.chg_controlInterval)*60000
            }        
    
            this.getTechInfo(oCharger)

            if( parseInt(this.App.$store.state.firstAsyncReadData)===0 || 
                parseInt(response.data.chg_updates)===1  ||     
                parseInt(response.data.chg_restartApp)===1) {
                
                this.App.$store.commit('oCurrentChargerToVuex', oCharger)
                
                setTimeout(() => {

                    let eViewSerialNo = document.getElementById('view-serial-no')  
                    console.log(eViewSerialNo)
                    if(eViewSerialNo!==null)    {
                        console.log('INN')
                        eViewSerialNo.classList.remove('display-none')
                        //<i class="fa-solid fa-charging-station"></i>
                        //console-orange.svg
                        setTimeout(() => {
                                eViewSerialNo.innerHTML = '&nbsp;&nbsp;&nbsp;&nbsp;[&nbsp; <img src="' +localStorage.pathIcons+ 'orange/charging-station-orange.svg" style="width:25px;" /> &nbsp;:&nbsp;' + oCharger.chg_serialNo.substring(0, oCharger.chg_serialNo.indexOf('@')) + ' ]'    
                        }, 500);

                        
                        //if(oCharger.chg_serialNo.indexOf('x')===5)
                            //eViewSerialNo.innerHTML = '[' + oCharger.chg_serialNo.substring(0, oCharger.chg_serialNo.indexOf('x')) + ']'
                        //else
                            
                    }
                }, 0);

                this.getConnectsForCharger(oCharger)
                this.App.$store.commit('firstAsyncReadDataToVuex', 1)
            }

            /*
            if( parseInt(oCharger.chg_updates)>0 || videoName.trim().length===0) {
                this.getVideoName(oCharger)
            }
            */
    
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (1)', error, formData)})
}

getConnectsForCharger   =   (oCharger)  =>  {

    console.log(oCharger)
    console.log(oCharger.chg_id)

    let formData = this.SPEC.formData(this.axios)
    formData.append('command',      'proc-getConnectsForCharger')    
    formData.append('chargerId',    oCharger.chg_id)    

    //let fields = '{"serialNo":"'+this.chargerDataRead.chg_serialNo+'"}'
    

    //let fields = '{"serialNo":"'+oCharger.chg_serialNo+'"}'
    let fields ='{"chargerId":"'+oCharger.chg_id+'"}'

    console.log(fields)
    formData.append('fields',    fields)    

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        console.log(response.data)
        console.log(typeof response.data)
        console.log(this.OBJ.count(response.data))

        if(!this.SPEC.isErrorResponseData('AsyncData.js (2)', formData, response, true, true))  {

            
            this.App.$store.commit('oChargerConnectsToVuex', response.data)

            let videoName = this.App.$store.state.promoVideo

            if(this.viewChargers)  {    
                //oCharger['videoId']
                console.log('videoId :: ' + oCharger.chg_videoId)
                console.log('videoName :: ' + videoName)
            }    

            //oCharger['updates']
            if( parseInt(oCharger.chg_updates)>0 || videoName.trim().length===0) {

                if(parseInt(oCharger.chg_updates)>0)
                    localStorage.weatherUpdates = 1
                else
                    localStorage.weatherUpdates = 0

                this.getVideoName(oCharger)
            }    
            //--------------------------END VIDEO            
        }
    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (2)', error, formData)})
}

getVideoName    =   (oCharger)  =>  {

    

    let formData = this.SPEC.formData(this.axios)
    formData.append('command',  'proc-getPromoVideo')    
    let fields = '{"serialNo":"'+oCharger.chg_serialNo+'"}'
    formData.append('fields',  fields)    
    
    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        if(!this.SPEC.isErrorResponseData('AsyncData.js (3)', formData, response, true, true))  {

            this.App.$store.commit('promoVideoToVuex', response.data['promo_video'])
            if(this.viewChargers)  { 
                console.log('VIDEO READ :: ' + this.App.$store.state.promoVideo)
            }   
    
            //oCharger['updates']
            if(this.countFlags===0 ||  parseInt(oCharger.chg_updates)>0) {
                this.flagsDataRead(oCharger)
            }            
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (3)', error, formData)})
}

flagsDataRead   =   (oCharger) =>  {    
    
    if(this.viewStartFunc)  console.log('START flagsDataRead()')
    

    let formData = this.SPEC.formData(this.axios)
    formData.append('command', 'proc-getCurrentFlags')    
    
    let fields  =   '{'
        fields  +=  '"chargerId":"'+oCharger.chg_id+'", '
        fields  +=  '"serialNo":"'+oCharger.chg_serialNo+'" '
        fields  +=  '}'
    
    formData.append('fields', fields )

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        if(!this.SPEC.isErrorResponseData('AsyncData.js (4)', formData, response, false, true))    {

            console.log(response.data)

            this.App.$store.commit('oFlagsFromThisChargerToVuex', response.data)

            if(this.viewFlags)  {console.log(response.data)}
    
            this.slidersDataRead(oCharger)
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (4)', error, formData)})
}

slidersDataRead =   (oCharger)  =>  {

    if(this.viewStartFunc)  console.log('START slidersDataRead()')

    let formData = this.SPEC.formData(this.axios)
    formData.append('command', 'proc-getSlidersActiveOnly')    
    //oCharger['id']
    let fields  =   '{"chargerId":"'+this.App.$store.state.oCurrentCharger.chg_id+'", '
        fields  +=  '"serialNo":"'+this.App.$store.state.oCurrentCharger.chg_serialNo+'"}'
    
    formData.append('fields', fields)    

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        if(!this.SPEC.isErrorResponseData('AsyncData.js (5)', formData, response, true, false))  {

            if(this.viewSliders)    {console.log(response.data)}

            this.App.$store.commit('oSlidersToVuex', response.data)
            //console.log(oCharger)
            //console.log(oCharger.chg_serialNo)

            //TO DO
            //this.regionCMSManageRead(oCharger)        

            this.helpContentDataRead(oCharger)
            
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (5)', error, formData)})
}

helpContentDataRead =   (oCharger)  =>  {

  
    if(this.viewStartFunc)  console.log('START helpContentDataRead()')
    
    let formData = this.SPEC.formData(this.axios)
    formData.append('command', 'proc-getHelpContent')    
    formData.append('chargerId', oCharger.chg_id)    
    
    let fields  =   '{"locale":"'+this.App.$store.state.oFlagsFromThisCharger[0].lng_code+'", '
        fields  +=  '"chargerId":"'+oCharger.chg_id+'"}'   
    formData.append('fields', fields )

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        if(!this.SPEC.isErrorResponseData('AsyncData.js (6)', formData, response, true, false))  {
           console.log(response.data)
            this.App.$store.commit('oHelpContentToVuex', response.data)
            this.getContentManage(oCharger)
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (6)', error, formData)})
}

/*
getAllCotentManage  =   (oCharger)  =>  {

    if(this.viewStartFunc)  console.log('START getAllCotentManage()')

    let identify = 'region-'+localStorage.projectName+'-'+oCharger.chg_serialNo.substring(0,5)
    let formData = this.SPEC.formData(this.axios)
    formData.append('command',  'proc-getAllCotentManage') 
    let fields  =   '{"identify":"'+identify+'"}'
    formData.append('fields',   fields)   

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {
        console.log(response.data)

        if(!this.SPEC.isErrorResponseData('AsyncData.js (71)', formData, response, true, false))  {

            if(this.viewCMS)    console.log(response.data)
            console.log(response.data)

            this.App.$store.commit('oRegionManageToVuex', response.data.cmsContent)
            this.App.$store.commit('oRegionInLinesToVuex', response.data.cmsInLine)
            this.App.$store.commit('oRegionInterTextsToVuex', response.data.cmsInterTexts)

            //NEXT WEATHER TO DO
            this.App.isAsyncDataRead = new Date;
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (72)', error, formData)})    
}
*/



getContentManage   =   (oCharger) =>  {


    if(this.viewStartFunc)  console.log('START regionCMSManageRead()')


    let serialNo = localStorage.netHeavenlyGate
   
    let identify = 'region-'+localStorage.projectName+'-'+serialNo.substring(0,7)

    let formData = this.SPEC.formData(this.axios)
    formData.append('command',  'proc-getContentManage') 

    let fields  =   '{"identify":"'+identify+'"}'

    formData.append('fields',   fields)   

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        console.log(response.data)

        if(!this.SPEC.isErrorResponseData('AsyncData.js (6)', formData, response, true, false))  {

            if(this.viewCMS)    console.log(response.data)

            //console.log(response.data)
            this.App.$store.commit('oRegionManageToVuex', response.data)
            this.getContentInLine(oCharger)
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (6)', error, formData)})
}

getContentInLine  =   (oCharger)  =>  {

    if(this.viewStartFunc)  console.log('START regionCMSLinesRead()')

    //oCharger['serialNo']
    let identify = 'region-'+localStorage.projectName+'-'+oCharger.chg_serialNo.substring(0,5)
    
    let formData = this.SPEC.formData(this.axios)
    formData.append('command',  'proc-getContentInLine') 
    let fields  =   '{"identify":"'+identify+'"}'
    formData.append('fields',   fields)   

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        //console.log(response.data)

        if(!this.SPEC.isErrorResponseData('AsyncData.js (7)', formData, response, true, false))  {

            if(this.viewCMS)    {console.log(response.data)}
       
            //console.log(response.data)
            this.App.$store.commit('oRegionInLinesToVuex', response.data)
            
            this.regionInterTextsRead(oCharger)
            
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (7)', error, formData)})
}

regionInterTextsRead =   (oCharger)  =>  {

    if(this.viewStartFunc)  console.log('START regionCMSInterTextsRead()')

    //oCharger['serialNo']
    let identify = 'region-'+localStorage.projectName+'-'+oCharger.chg_serialNo.substring(0,7)

    let formData = this.SPEC.formData(this.axios)
    formData.append('command',  'proc-getInterTexts') 
    let fields  =   '{"identify":"'+identify+'"}'
    formData.append('fields',   fields)   

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        console.log(response.data)

        if(!this.SPEC.isErrorResponseData('AsyncData.js (8)', formData, response, true, false))  {
            if(this.viewCMS)    {console.log(response.data)}        
       
            //console.log(response.data)
            this.App.$store.commit('oRegionInterTextsToVuex', response.data)
                                    
            //NEXT WEATHER TO DO
            this.App.isAsyncDataRead = new Date;

        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (8)', error, formData)})  

}


/*  to do remove
updateCharger   =   (fieldName, value, chargerId)  =>  {
    let formData = this.SPEC.formData()
    formData.append('command',  'update')
    formData.append('tables',   'chargers')
    formData.append('fields',   '{"'+fieldName+'":"'+value+'"}')
    formData.append('where',    'id='+chargerId)
        this.SPEC.writeToLogIfError (response.data, formData)
    })
}
*/

getTechInfo   =   (oCurrentCharger)  =>  {

    //if(this.viewStartFunc)  console.log('getTechInfo :: ' + this.DT.timeFromTimeStamp())

    let formData = this.SPEC.formData(this.axios)
    ///formData.append('command', 'ip')
    formData.append('command', 'proc-getCurrentIP')

    let oTechInfo = []

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        //console.log(response.data)
        if(!this.SPEC.isErrorResponseData('AsyncData.js (9)', formData, response, true, true))  {
            let isAdminIP = false
            for(let ip of response.data['oAdminsIP'])   {
                if(ip['admin-IP'].toString().trim()===response.data['current-ip'].toString().trim())    {
                    isAdminIP = true
                    break
                }
            }
            
            oTechInfo ={    "debugger":         oCurrentCharger['chg_debugger'],
                            "currentIP":        response.data['current-ip'],
                            "currentHostAddr":  response.data['current-host-addr'],
                            "adminIP":          response.data['admin-ip'],
                            "isAdminIP":        isAdminIP,
                            "serialNo":         oCurrentCharger['chg_serialNo'],
                            "axiosPath":        localStorage.restAPI}
    

            //if(this.viewResponse)   console.log(oTechInfo)
            this.App.$store.commit('oTechInfoToVuex', oTechInfo)
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (9)', error, formData)})
}


startRestartApp  =   (oCharger)  =>  {

    console.log(oCharger)
    console.log(oCharger[0])

    let formData = this.SPEC.formData(this.axios)
    formData.append('command',      'proc-setRestartApp')
    formData.append('chargerId',    oCharger.chg_id)
    formData.append('fields',       '{"serialNo":"'+oCharger.chg_serialNo+'"}')

    //formData.append('command',  'update')
    //formData.append('tables',   'chargers')
    //formData.append('fields',   '{"restartApp":0}')
    //formData.append('where',    'id='+chargerId)
    
    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {
        
        if(!this.SPEC.isErrorResponseData('AsyncData.js (10)', formData, response, true, true))  {
            if(typeof response.data.restartApp==='string')
                if(parseInt(response.data.restartApp)===1)
                    this.LIB.restartApp()  
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (10)', error, formData)})
}

handWithAnimation   =   (oCharger)  =>  {
    //61
    //1000    
    let notHand = false
    if(typeof this.App.$store.state.oSet === 'object' && typeof this.App.$store.state.oCurrentIP === 'object') {
        for(let oNotAnimation of this.App.$store.state.oSet['not-hand-animation'])   {
            if(oNotAnimation.ip.trim()===this.App.$store.state.oCurrentIP['current-ip'].trim())  {
                notHand = !notHand
                break
            }
        }
    }

    if(notHand)    return

    let eScreenWithClock    =   document.getElementById('screen-with-clock')   
    let eAsyncDataReadFirst    =   document.getElementById('async-data-read-first')   
    
    if(eScreenWithClock!==null && eAsyncDataReadFirst!==null) {

        if(eAsyncDataReadFirst.classList.contains('display-none'))  {

            let setTimeOut = 0,  setTimeStep = 2000

            let setCycle = ((oCharger.chg_controlInterval*30)*1000)

            eScreenWithClock.classList.remove('bg-full')
            eScreenWithClock.classList.remove('bg-withouth-click')

            eScreenWithClock.classList.add('bg-withouth-click')

            let handShot, aHandShot = []

            for(let handShot of this.App.$store.state.aHandShots)   
                clearInterval(handShot)

            this.$store.commit('aHandShotsToVuex', [])

            for(let i=0; i<setCycle; ++i) {

                setTimeOut += parseInt(setTimeStep)
                handShot = setTimeout(() => {

                    if(i%2===0) {
                        eScreenWithClock.classList.remove('bg-withouth-click')
                        eScreenWithClock.classList.add('bg-full')
                    } else {
                        eScreenWithClock.classList.remove('bg-full')
                        eScreenWithClock.classList.add('bg-withouth-click')                                
                    }
                }, setTimeOut);

                aHandShot.push(handShot)
            }
        }
    }   

}

}
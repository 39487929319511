export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slovinčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si"])},
  "helper": {
    "do-you-need-advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrebujete nasvet ?"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polnilnik ni neaktiven ali na servisu"])},
  "is-excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacijo je trenutno onemogočil administrator"])},
  "async-read-data-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počakajte, da aplikacija naloži podatke – posodobite svojo shrambo podatkov."])},
  "async-read-data-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priporočljivo je, da pregledate celotno aplikacijo, da preverite tudi njeno delovanje,"])},
  "async-read-data-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kot tudi iz razloga, da se v podatkovno skladišče naloži celotna vsebina aplikacije, vključno z zasloni."])},
  "async-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iščem polnilec za moje električno kolo - vsaj 3 znake"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacija vam lahko poišče polnilni modul, ki je združljiv z znamko vašega stroja"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacija začne iskati združljiv polnilni modul šele po vnosu vsaj treh znakov"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Električnega stroja, ki ga iščete, trenutno ni v katalogu aplikacij"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Več informacij na WEB strani"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vremenska napoved trenutno ni na voljo !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urna vremenska napoved"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smer vetra"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dež"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitrost vetra"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunki vetra"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zračni tlak"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sneženje"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sončni vzhod"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sončni zahod"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sončno"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pol jasno"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oblačno"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skoraj oblačno"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["popolnoma oblačno"])}
  },
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAZAJ"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZEMLJEVID"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JEZIKI"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VREME"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGIJA"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRIKLJUČEK"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POMOČ"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meni aplikacij"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta del aplikacije začasno ni na voljo"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polnilnik trenutno ne podpira skupnega WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacija za svojo funkcionalnost zahteva zaslon z minimalno ločljivostjo: širina 800 in višina 600 slikovnih pik"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nedelja"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ponedeljek"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torek"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sreda"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["četrtek"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petek"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sobota"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["januar"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["februar"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marec"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aprila"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maja"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["junija"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["julija"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avgusta"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["septembra"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oktobra"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["novembra"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["decembra"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastnik polnilnice se je odločil, da javnega omrežja WIFI ne deli iz tehničnih razlogov"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupno omrežje WIFI je na voljo v bližini polnilnika"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vpiši se"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslo"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprostite, vsebino pripravljamo."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprostite,~orientacijski zemljevid za ta polnilnik trenutno ni na voljo"])}
  }
}
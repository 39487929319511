export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bosniančina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ba"])},
  "helper": {
    "do-you-need-advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trebate li savjet ?"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punjač je neaktivan ili je u servisu"])},
  "is-excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikaciju je trenutno onemogućio administrator"])},
  "async-read-data-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pričekajte dok aplikacija učita podatke - ažurira svoju pohranu podataka."])},
  "async-read-data-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preporučljivo je proći kroz cijelu aplikaciju i provjeriti njenu funkcionalnost,"])},
  "async-read-data-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kao i iz razloga što se kompletan sadržaj aplikacije, uključujući ekrane, učitava u skladište podataka."])},
  "async-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tražim punjač za svoj električni bicikl - najmanje 3 znaka"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacija vam može pronaći modul za punjenje kompatibilan s markom vašeg uređaja"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacija počinje tražiti kompatibilni modul za punjenje tek nakon što unese najmanje tri znaka"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Električna mašina koju tražite trenutno nije pronađena u katalogu aplikacija"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Više informacija na WEB stranici"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vremenska prognoza trenutno nije dostupna!"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vremenska prognoza po satu"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smjer vjetra"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kisa"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brzina vjetra"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naleti vjetra"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zračni pritisak"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snijeg"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["izlazak sunca"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zalazak sunca"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunčano"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polujasno"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oblačno"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skoro oblačno"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["potpuno oblačno"])}
  },
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAZAD"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAP"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JEZICI"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VRIJEME"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGION"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONNECTOR"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POMOĆ"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meni aplikacija"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovaj dio aplikacije je privremeno nedostupan"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punjač trenutno ne podržava zajednički WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za svoju funkcionalnost, aplikaciji je potreban ekran minimalne rezolucije: širine 800 i visine 600 piksela"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedjelja"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ponedjeljak"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utorak"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["srijeda"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["četvrtak"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["petak"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subota"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["februar"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mart"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["april"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maja"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juna"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jula"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avgust"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["septembra"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oktobar"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["novembar"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["decembar"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlasnik punjača odlučio je da ne dijeli javnu WIFI mrežu iz tehničkih razloga"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zajednička WIFI mreža dostupna je u blizini punjača"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulogovati se"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinka"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žao nam je, pripremamo sadržaj."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žao nam je,~ali orijentacijska karta za ovaj punjač trenutno nije dostupna"])}
  }
}
export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["srbčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rs"])},
  "helper": {
    "do-you-need-advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да ли вам треба савет ?"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пуњач је неактиван или је у току сервисирања"])},
  "is-excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апликацију је тренутно онемогућио администратор"])},
  "async-read-data-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сачекајте да апликација учита податке - ажурирајте своје складиште података."])},
  "async-read-data-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Препоручује се да прођете кроз целу апликацију и да проверите њену функционалност,"])},
  "async-read-data-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["као и из разлога што се комплетан садржај апликације, укључујући и екране, учитава у складиште података."])},
  "async-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тражим пуњач за свој електрични бицикл - најмање 3 карактера"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апликација вам може пронаћи модул за пуњење компатибилан са брендом ваше машине"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апликација почиње да тражи компатибилни модул за пуњење тек након што унесе најмање три знака"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електрична машина коју тражите тренутно није пронађена у каталогу апликација"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Више информација на ВЕБ страници"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временска прогноза тренутно није доступна !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временска прогноза по сату"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правац ветра"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Киша"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брзина ветра"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налети ветра"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Притисак ваздуха"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Снег"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["излазак cунца"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["залазак сунца"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cунчан"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["полујасан"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["облачно"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["скоро облачно"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["потпуно облачно"])}
  },
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НАЗАД"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["МАПА"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЈЕЗИЦИ"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВРЕМЕ"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНФО РЕГИОН"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВИ-ФИ"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЦОННЕЦТОР"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОМОЋ"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мени апликације"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Овај део апликације је привремено недоступан"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пуњач тренутно не подржава дељени ВИФИ"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За своју функционалност, апликацији је потребан екран минималне резолуције: ширине 800 и висине 600 пиксела"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["недеља"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понедељак"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["уторак"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среда"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["четвртак"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["петак"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субота"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["јануара"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["фебруара"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["марта"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Април"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Може"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["јуна"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Јул"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["августа"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["септембар"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["октобар"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["новембра"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["децембар"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник пуњача је одлучио да не дели јавну ВИ-ФИ мрежу из техничких разлога"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У близини пуњача је доступна заједничка ВИ-ФИ мрежа"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пријавите се"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лозинка"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извините, припремамо садржај."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жао нам је,~али оријентацијска мапа за овај пуњач тренутно није доступна"])}
  }
}
export default class dateTime {

constructor() {}

timeFromTimeStamp   =  () =>   {
    let timeStamp = new Date().toString()
    let twoPoints = timeStamp.indexOf(':')
    timeStamp = timeStamp.substring(twoPoints-2, (twoPoints-2)+8)
    return timeStamp
}

datePlusMinus   =   (date, days, plus=true)  =>  {
    if(!this.isJSDateTime(date))  {date = this.dateTimeConstructor(date)}    

    if(typeof days !== 'number' )   return date
    if(days<1)                      return date

    if(plus)    
        date.setDate(date.getDate()+days)
    else 
        date.setDate(date.getDate()-days)

    return date
}



/*
timeFromTimeStamp   =  () =>   {
    let timeStamp = new Date().toString()
    let aTimeStamp = timeStamp.split(' ')
    return aTimeStamp[4]
}
*/
getTimeLimit = (dateTo='', dateFrom='') => {


    if(typeof dateTo !== 'string' && typeof dateTo !== 'object') return   99999999999999
    if(typeof dateFrom !== 'string' && typeof dateFrom !== 'object') return 99999999999999

    if(typeof dateTo === 'string') {
        if(dateTo.trim().length===0) return 99999999999999
        if(!this.isJSDateTime(dateTo))  {dateTo = this.dateTimeConstructor(dateTo)}
    }

    if(typeof dateFrom === 'string') {
        if(dateFrom.trim().length===0) return 99999999999999
        if(!this.isJSDateTime(dateFrom)) {dateFrom = this.dateTimeConstructor(dateFrom)}    
    }
        

    //console.log('DT to '+dateTo)
    //console.log('DT from '+dateFrom)    
    //console.log(this.dateDiff(dateTo, dateFrom).mlSecs)


    return this.dateDiff(dateTo, dateFrom).mlSecs
}

//------------------------------------------------------------------------------

limitIn = (dateTo, dateFrom, limit=((3*60)*1000)) => {

   
    if(!this.isJSDateTime(dateTo))  {dateTo = this.dateTimeConstructor(dateTo)}
   
    
    if(!this.isJSDateTime(dateFrom)) {dateFrom = this.dateTimeConstructor(dateFrom)}

    let  dateDiff = this.dateDiff(dateTo, dateFrom)

    return limit > dateDiff.mlSecs
}


isJSDateTime = (JSDateTime='') => {
    
    return JSDateTime.toString().indexOf('GMT+') > (-1)
}


stdFromJSDateTime = (JSDateTime, isTime=true, plusSeconds=true) => {
    //console.log(JSDateTime)
    let years = JSDateTime.getFullYear()
    let months = this.addZero( JSDateTime.getMonth()+1, 2)
    let days = this.addZero( JSDateTime.getDate(), 2)
    let hours = this.addZero( JSDateTime.getHours(), 2)
    let minutes = this.addZero( JSDateTime.getMinutes(),  2)
    let seconds = this.addZero( JSDateTime.getSeconds(),  2)

    if(isTime)  {
        if(plusSeconds) {
            return years + '-' + months + '-' + days + ' ' + hours + ':' + minutes + ':' + seconds
        }
        if(!plusSeconds) {
            return years + '-' + months + '-' + days + ' ' + hours + ':' + minutes
        }
    }

    else
        return years + '-' + months + '-' + days

    
}

getSeconds = (dateTime='')  =>  {

    //console.log(this.dateTimeConstructor(dateTime).getTime())

    return this.dateTimeConstructor(dateTime).getTime()
}

dateTimeConstructor = (dateTime='') =>   {

    let aTime
    let aDateTime = dateTime.split(' ')
    let aDate = aDateTime[0].split('-')
    if(aDateTime.length>1)
        aTime = aDateTime[1].split(':')

    if(aDateTime.length>1)
        return new Date(aDate[0], (aDate[1]-1), aDate[2], aTime[0], aTime[1], aTime[2])
    else 
        return new Date(aDate[0], (aDate[1]-1), aDate[2])
} 

dayName =   (date)  =>  {

    let dayName = date.getDay().toString()

    return dayName
}

monthName =   (date)  =>  {

    let monthName = date.getMonth().toString()

    return monthName
}

isParamHourBeforeCurrentHour = (hour='00:00') =>  {

    hour = parseInt(hour.split(':')[0])
    let currentHour = new Date().getHours()

    //console.log(hour)
    //console.log(currentHour)
    //console.log(parseInt(hour) >= parseInt(currentHour))

    return hour >= currentHour
}

dateDiff = (dateTo, dateFrom) => {

    if(!this.isJSDateTime(dateTo)) dateTo = this.dateTimeConstructor(dateTo)
    if(!this.isJSDateTime(dateFrom)) dateFrom = this.dateTimeConstructor(dateFrom)

    let diffValue = Math.abs(dateTo - dateFrom) / 1000

    // calculate (and subtract) whole days
    var days = Math.floor(diffValue / 86400);
    diffValue -= days * 86400;

    // calculate (and subtract) whole hours
    let hours = Math.floor(diffValue / 3600) % 24;
    diffValue -= hours * 3600;

    // calculate (and subtract) whole minutes
    let minutes = Math.floor(diffValue / 60) % 60;
    diffValue -= minutes * 60;

    // what's left is seconds
    let seconds = diffValue % 60;  // in theory the modulus is not required

    let sumHours = parseInt((parseInt(days)*24) +  parseInt(hours))
    let sumMinutes = parseInt(((parseInt(days)*24)*60) +  (parseInt(hours)*60) + parseInt(minutes))
    let sumSeconds = parseInt((((parseInt(days)*24)*60)*60) +  ((parseInt(hours)*60)*60) + (parseInt(minutes)*60) + (seconds))
    let sumMlSeconds = parseInt(parseInt(parseInt((((parseInt(days)*24)*60)*60)*1000) + parseInt(((parseInt(hours)*60)*60)*1000) + parseInt((parseInt(minutes)*60)*1000) + (parseInt(seconds)*1000)))

    /*
    console.log('IN START ---')
    console.log(dateTo)
    console.log(dateFrom)
    console.log('days :: ' + days)
    console.log('hours :: ' + hours)
    console.log('minutes :: ' + minutes)
    console.log('seconds :: ' + seconds)
    console.log('mlSecs :: ' + Math.abs(dateTo - dateFrom))
    console.log('IN SUMARS ---')    
    console.log('hours :: ' + sumHours)
    console.log('minutes :: ' + sumMinutes)
    console.log('seconds :: ' + sumSeconds)
    console.log('mlSecs :: ' + sumMlSeconds)
    console.log('IN STOP ---')    
    */
   
    let oReturnValues   = {     "mlSecs": Math.abs(dateTo - dateFrom),
                                "days":days,
                                "hours":hours,
                                "mins":minutes,
                                "secs":seconds,
                                "sumDays":days,
                                "sumHours":sumHours,
                                "sumMins":sumMinutes,
                                "sumSeconds":sumSeconds ,
                                "sumMlSecs": sumMlSeconds}

    return oReturnValues
}

limitIn = (dateTo, dateFrom, limit=((3*60)*1000)) => {

   
    if(!this.isJSDateTime(dateTo))  {dateTo = this.dateTimeConstructor(dateTo)}
   
    
    if(!this.isJSDateTime(dateFrom)) {dateFrom = this.dateTimeConstructor(dateFrom)}

    let  dateDiff = this.dateDiff(dateTo, dateFrom)

    return limit > dateDiff.mlSecs
}

addZero( paramNumber, lengthNumber) {
    
    let returnNumber = '' + paramNumber

    while (returnNumber.length < lengthNumber) returnNumber = '0' + returnNumber

    return returnNumber
}

SKFormat = (AMDate='', dateOrTime=0, dateSepar='.') => {
    if(AMDate.toString().trim().length===0) return AMDate
    if(!this.isDateTimeAMFormat(AMDate))    return AMDate

    let aDateTime = AMDate.split(' ')
    let aDate = aDateTime[0].split('-')
    let aTime = aDateTime[1].split(':')

    let date = aDate[2] + dateSepar + aDate[1] + dateSepar + aDate[0]
    let time = aTime[0] + ':' + aTime[1] + ':' + aTime[2]
    switch(dateOrTime) {
        case 0: return date
        case 1: return time
        case 2: return time + ' ' + date 
        case 3: return date + ' ' + time
    }

    return date + ' ' + time

}

isDateTimeAMFormat = (dateTime='') => {

    let myRegExp=   /^\d{4}-[0-1][0-2]-[0-3]\d\s([0-1][0-9]|2[0-3]):[0-5]\d$/
    
    if(dateTime.indexOf(':')>(-1) && dateTime.lastIndexOf(':')>(-1)) 
        if(dateTime.indexOf(':') !== dateTime.lastIndexOf(':')) 
            dateTime = dateTime.substring(0, dateTime.lastIndexOf(':'))

    return dateTime.match(myRegExp)
}
    
}
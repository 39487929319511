
<!--this.PROC.dbg('isAsyncDataRead()' ,'App.vue')-->
<!--this.DBG.con(JSON.stringify(this.oCurrentCharger) , 'App.vue', 284, true)-->

<template>



    <div  id="global-work-space" :key="rerenderApp" class="">


        <div style="font-size:1em;" />
        
        <div v-if="okDisplay()" id="ok-display">

            <div    v-if="parseInt(isActiveApp)===1 && parseInt(isExcluded)===0"
                    id="async-data-progress"    />

            <span   v-if="parseInt(isActiveApp)===1 && parseInt(isExcluded)===0" 
                    id="async-data-read-first" 
                    class="display-none">
                
                <!--{{ oCurrentCharger.chg_serialNo }}-->
                    
                <!--Aplikácia cez API načítava dáta pre aplikáciu - vytvára sa dátový sklad.-->
                <span v-if="islocale()"  :key="localeRender">
                    {{ $t('async-read-data-1') }}
                </span>
                

                <span id="view-serial-no" class="display-none" /> 
                
            </span>

            <div    v-if="parseInt(isActiveApp)===1 && parseInt(isExcluded)===0"
                    id="async-data-read" 
                    class="setDown display-none">

                <span   :key="asyncRender"  >

                    <!--Odporúča sa prejsť celou aplikáciou aj kvôli kontrole jej funkčnosti-->
                    <!--ako aj z dôvodu, aby sa do dátového skladu načítal úplný obsah aplikácie aj s obrazovkami.-->                    
                    <table border="0" width="100%">
                        <tr>
                            <td valign="middle" style="height:160px;">
                                <span v-if="islocale()"  :key="localeRender">
                                    {{ $t('async-read-data-2') }}<br />
                                </span>
                                
                                <span v-if="islocale()"  :key="localeRender">
                                    {{ $t('async-read-data-3') }}
                                </span>
                                
                            </td>
                        </tr>
                    </table>

                </span>
            </div>

            <!--TEMPORARY-->
            <router-view   />

        </div>

        <div v-else-if="!okDisplay()" id="small-display" class="small-display">
            {{ $t('small-display') }}.
        </div>

        <!--<DebuggerWin   />-->

        <ViewConsoleInApp   :propWidth="1000"    
                            :propHeight="600"
                            :propTopHeight="40"
                            :propBottomHeight="40"   />
        
        <div    :key="rerenderTechInfo">
            <TechInfoForAdmin   v-if="parseInt(startDebugger)===1" 
                                :adminIP="adminIP" 
                                :currentIP="currentIP" 
                                :serialNo="serialNo"
                                :restAPI="axiosPath"  
                                :techInfo="techInfo"  
                                :key="rerenderTechInfo"     />
        </div>

    </div>

</template>

<script>
    import  setApp                                      from    "@/App.json"
    import  axios                                       from    "axios"

    import  DT                                          from    '@/assets/modules/DateTime'
    import  ASYNC                                       from    '@/assets/modules/AsyncData'
    import  URL                                         from    '@/assets/modules/URLMethodsClass'
    import  OBJ                                         from    '@/assets/modules/OBJMethodsClass'
    import  SPEC                                        from    '@/assets/modules/Specials'
    import  PROC                                        from    '@/assets/modules/dbProc'
    import  DBG                                         from    '@/assets/modules/Debugger'
    import  LIB                                         from    '@/assets/modules/Library'

    import  TechInfoForAdmin                            from    '@/components/TechInfoForAdmin.vue'
    import  ViewConsoleInApp                            from    '@/components/ViewConsoleInApp.vue'

    //import  DebuggerWin                                 from    '@/components/DebuggerWin.vue'

    export default {
        name:'app',
        created () {
            window.addEventListener(  'click',  this.restartComeBack)
        },
        components: {
            TechInfoForAdmin,
            ViewConsoleInApp,
            //DebuggerWin,
        },
        data() {
            return {
                ASYNC:                                  new ASYNC(this),
                DT:                                     new DT(this),
                URL:                                    new URL(this),
                OBJ:                                    new OBJ(this),
                SPEC:                                   new SPEC(this),
                PROC:                                   new PROC(this),
                DBG:                                    new DBG(this),
                LIB:                                    new LIB(this),

                scriptPath:                            '@/',

                smallDisplayMargin:                     (screen.height/3) + 'px',

                oTechInfo:                              [],
                oCurrentCharger:                        this.$store.state.oCurrentCharger,
                oFlagsFromThisCharger:                  [],
                oSliders:                               [],
                oBasicSecurity:                         [],
                oRestartURL:                            [],
                oNetHeavenlyGate:                       [],
                oCurrentLocale:                         [],

                displaySimulator:                       0,

                //For Async Read Data
                isAsyncDataRead:                        '',
                isActiveApp:                            0,
                isExcluded:                             0,
                //=================================

                //Tech info

                isAdminIP:                              this.$store.state.oTechInfo.isAdminIP,
                adminIP:                                this.$store.state.oTechInfo.adminIP,
                currentIP:                              this.$store.state.oTechInfo.currentIP,
                currentHostAddr:                        this.$store.state.oTechInfo.currentHostAddr,
                serialNo:                               this.$store.state.oTechInfo.serialNo,
                axiosPath:                              this.$store.state.oTechInfo.axiosPath,
                startDebugger:                          this.$store.state.oTechInfo.debugger,              


                oSet:                                   [],
                oChargers:                              [],
                oCurrentIP:                             [],

                restAPI:                                '',
                techInfo:                               0,

                //=================================
                isDatachange:                           0,

                rerenderTechInfo:                       new Date(),    
                rerenderApp:                            new Date(),    
                asyncRender:                            new Date(),    
                localeRender:                           new Date(),    
            }
        },
        watch:  {
            oCurrentLocale()    {

                if(this.OBJ.count(this.oCurrentLocale)>0)   {

                    this.$i18n.locale = this.oCurrentLocale['lng_code']

                    setTimeout(() => {
                        let eAsyncDataReadFirst = document.getElementById('async-data-read-first')
                        if(eAsyncDataReadFirst!==null) eAsyncDataReadFirst.classList.remove('display-none')
                    }, 500);

                    setTimeout(() => {
                        let eAsyncDataRead = document.getElementById('async-data-read')
                        let today = this.DT.stdFromJSDateTime(new Date(), true, true)
                        let oDiff = this.DT.dateDiff(today, this.oNetHeavenlyGate[0].chg_nextStart)
                        if(parseInt(oDiff.sumHours)>parseInt(this.oSet['limit-next-start']))    
                            if(eAsyncDataRead!==null) eAsyncDataRead.classList.remove('display-none')
                    }, 1500);
                }
            },
            oNetHeavenlyGate()    {

                this.asyncRender = new Date()
                
            },
            oBasicSecurity()    {

                localStorage.startApp               =   1
                localStorage.useAPI                 =   this.getUseAPI(localStorage.restAPI)
                
                localStorage['from-app']            =   this.oBasicSecurity.app['from-app']
                localStorage['project-local']       =   this.oBasicSecurity.app['project-local']
                localStorage['project-tester']      =   this.oBasicSecurity.app['project-tester']
                localStorage['project-name']        =   this.oBasicSecurity.app['project-name']

                this.PROC.getProjectSetup('App.vue')
                this.PROC.getCurrentIP('App.vue')
            },
            oRestartURL()   {

                console.log(this.oRestartURL)
                if(this.OBJ.count(this.oRestartURL)>0)  {
                    
                    localStorage.restartURL = this.oRestartURL[0].chg_otherAppURL
                }
                    
                else
                    localStorage.restartURL = ''   
            },
            oSet()  {

                if(localStorage.getParameterFromURL.trim().length===0)  {
                    localStorage.getParameterFromURL = this.oSet['heavenly-gate'].developer
                }

                if(this.OBJ.count(this.oSet)>0 && this.OBJ.count(this.oCurrentIP)>0)    {
                    let isExcluded
                    //'App.vue 1',
                    isExcluded = this.LIB.getIsExcluded(this.oSet)
                    localStorage.isExcluded = isExcluded
                    this.isExcluded         = isExcluded
                }

                localStorage.pathIcons  =   this.oSet.url.icons
                localStorage.videos     =   this.oSet.url.videos
                localStorage.URLFlags   =   this.oSet.url.flags
                localStorage.URLslider  =   this.oSet.url.sliders

                this.$store.commit('oSetToVuex', this.oSet)

                if(this.OBJ.count(this.oCurrentIP)>0)   {
                    this.createHeavenlyGate()

                    this.getFirstCurrentLocale(localStorage.netHeavenlyGate)
                    this.mountedAfterSecurity()

                }
            },

            //----------------------------------------
            oCurrentIP()    {

                this.$store.commit('oCurrentIPToVuex', this.oCurrentIP)

                if(this.OBJ.count(this.oSet)>0 && this.OBJ.count(this.oCurrentIP)>0)    {
                    let isExcluded
                    //'App.vue 2'
                    isExcluded = this.LIB.getIsExcluded(this.oSet)
                    localStorage.isExcluded = isExcluded
                    this.isExcluded         = isExcluded
                }                

                let isAdminIP   = this.LIB.isAdmin('admin-IP')
                let isTesterIP  = this.LIB.isAdmin('tester-IP');

                let isLocalhost = window.location.href.toLowerCase().indexOf('http://localhost:')>(-1)

                if(this.OBJ.count(this.oNetHeavenlyGate)>0 && !isLocalhost) {

                    if( this.oNetHeavenlyGate[0]['netHeavenlyGate'].trim()==='unauthorized' && 
                        !isAdminIP && 
                        !isTesterIP)    {

                        if(this.OBJ.count(this.oSet)>0) {
                            if(this.oSet.unauthorized.indexOf('http')===(0)) {

                                window.location.href = this.oSet.unauthorized.trim()
                            } else {

                                if(this.oSet.unauthorized.indexOf('notAllowAccess')>(-1)) {

                                    window.location.href = this.oSet['app-server']+'/notAllowAccess'
                                }
                            }
                        }
                    }
                    this.PROC.sendCentralServerApp( localStorage.netHeavenlyGate,
                                                    isLocalhost ? 1 : 0)                    
                }

                if(this.OBJ.count(this.oSet)>0)   {
                    this.createHeavenlyGate()

                    this.getFirstCurrentLocale(localStorage.netHeavenlyGate)
                    this.mountedAfterSecurity()

                }                
                
            },            
            oCurrentCharger()   {
                //console.log('WATCH oCurrentCharger')
                //this.DBG.con('DÁTA KU TEJTO NABÍJAČKE', 'App.vue', 283, true)
                //this.DBG.con(JSON.stringify(this.oCurrentCharger) , 'App.vue', 284, true)                
            },
            oChargers() {

                this.$store.commit('oAllChargersToVuex', this.oChargers)
            },
            isAsyncDataRead()   {

                //this.PROC.dbg('isAsyncDataRead()' ,'App.vue')
                let aURL        =   window.location.href.split('/')
                let aOtherURL   =   this.$store.state.oCurrentCharger.chg_otherAppURL.split('/')

                let isLocalhost = window.location.href.toLowerCase().indexOf('http://localhost:')>(-1)

                if(!isLocalhost)  
                    if(this.$store.state.oCurrentCharger.chg_otherAppURL.trim().length>0) 
                        if(aURL[2].trim() !== aOtherURL[2].trim())  
                            this.DBG.restartApp(localStorage.netHeavenlyGate)

                let oFlags = this.$store.state.oFlagsFromThisCharger

                if(this.OBJ.count(oFlags)>0) {

                    setTimeout(() => {
                        
                        this.$store.commit('asyncDataReadToVuex', 1)

                        this.$i18n.locale = oFlags[0]['lng_code']
                        localStorage.currentLocale = oFlags[0]['lng_code']

                        let eAsyncDataProgress = document.getElementById('async-data-progress')
                        let eAsyncDataReadFirst = document.getElementById('async-data-read-first')
                        let eAsyncDataRead = document.getElementById('async-data-read')

                        if(eAsyncDataProgress!==null)   
                            if(!eAsyncDataProgress.classList.contains('display-none'))  
                                eAsyncDataProgress.classList.add('display-none')
                            
                        if(eAsyncDataReadFirst!==null)  
                            if(!eAsyncDataReadFirst.classList.contains('display-none'))     
                                eAsyncDataReadFirst.classList.add('display-none')

                        if(eAsyncDataRead!==null)   {

                            eAsyncDataRead.classList.remove('setDown')
                            eAsyncDataRead.classList.add('setUp')
                        }
                            
                        let today = this.DT.stdFromJSDateTime(new Date(), true, true)
                        let oDiff = this.DT.dateDiff(today, this.oNetHeavenlyGate[0].chg_nextStart)
                        if(!(parseInt(oDiff.sumHours)>parseInt(this.oSet['limit-next-start'])))    
                            if(eAsyncDataRead!==null)   
                                if(!eAsyncDataRead.classList.contains('display-none'))   
                                    eAsyncDataRead.classList.add('display-none')

                        let isLocalhost = window.location.href.toLowerCase().indexOf('http://localhost:')>(-1)
                        
                        if(!isLocalhost)    
                            this.PROC.sendCentralServerApp( localStorage.netHeavenlyGate, 0)
                            

                        let eScreenWithClock    =   document.getElementById('screen-with-clock')   
                        if(eScreenWithClock!==null) {

                            if(eScreenWithClock.classList.contains('bg-withouth-click'))
                                eScreenWithClock.classList.remove('bg-withouth-click')
                            
                            eScreenWithClock.classList.add('bg-full')
                        }

                    }, 15000);
                }

                let tempGlobalInterval = this.$store.state.oCurrentCharger.chg_globalInterval
                let tempControlInterval = this.$store.state.oCurrentCharger.chg_controlInterval

                if(parseInt(tempGlobalInterval)>parseInt(tempControlInterval))
                    tempControlInterval = tempGlobalInterval

                this.$store.state.oCurrentCharger.chg_globalInterval = tempGlobalInterval
                this.$store.state.oCurrentCharger.chg_controlInterval = tempControlInterval

                localStorage.devGlobalInt   =   this.$store.state.oCurrentCharger.chg_globalInterval*60000
                localStorage.devControlInt  =   this.$store.state.oCurrentCharger.chg_controlInterval*60000

                localStorage.isActiveApp    =   this.$store.state.oCurrentCharger.chg_active
                this.isActiveApp            =   this.$store.state.oCurrentCharger.chg_active

                //console.log(this.$store.state.oCurrentCharger)

                //console.log(this.$store.state.oRegionManageCMS)
                //console.log(this.$store.state.oRegionLinesCMS)/
                //console.log(this.$store.state.oRegionInterTextsCMS)

                //console.log(this.$store.state.oSliders)

                //console.log(this.$store.state.oTechInfo)

                this.isAdminIP          = this.$store.state.oTechInfo.isAdminIP
                this.adminIP            = this.$store.state.oTechInfo.adminIP
                this.currentIP          = this.$store.state.oTechInfo.currentIP
                this.currentHostAddr    = this.$store.state.oTechInfo.currentHostAddr
                this.serialNo           = this.$store.state.oTechInfo.serialNo
                this.axiosPath          = this.$store.state.oTechInfo.axiosPath
                this.startDebugger      = this.$store.state.oTechInfo.debugger

                localStorage.isAdminIP          = this.isAdminIP
                localStorage.adminIP            = this.adminIP
                localStorage.currentIP          = this.currentIP
                localStorage.currentHostAddr    = this.currentHostAddr
                localStorage.serialNo           = this.serialNo
                localStorage.axiosPath          = this.axiosPath
                localStorage.startDebugger      = this.startDebugger

                //this.rerenderApp        = new Date()
                this.rerenderTechInfo   = new Date()
                this.isDatachange = 1
            },
            isDatachange()  {
                
                this.isDatachange = 0
                clearInterval(this.controlInterval)
                clearInterval(this.globalInterval)                
                this.controlInterval = null
                this.globalInterval = null

                this.controlInterval =  setInterval(() => this.ASYNC.chargerDataRead(), localStorage.devControlInt)
                this.globalInterval  =  setInterval(() => this.comeBackEvents_app(2), localStorage.devGlobalInt)
            },
            isActiveApp()   {

                localStorage.isActiveApp = this.isActiveApp
                this.rerenderTechInfo = new Date()
            },
        },
        methods: {
            islocale()  {

                return typeof this.$i18n.locale !== 'undefined'
            },

            getFirstCurrentLocale(netHeavenlyGate) {

                let formData = new FormData()
                formData.append('command', 'proc-getFirstCurrentLocale')
                formData.append('other-protocol', setApp['other-protocol'])
                formData.append('serialNo', netHeavenlyGate)    
                localStorage.restAPI = this.setRestApi()

                axios.post(localStorage.restAPI, formData).then(response => {
                    console.log(response.data)
                    if(typeof response.data === 'object')   {
                        this.oCurrentLocale = response.data
                        this.$i18n.locale = this.oCurrentLocale[0].lng_code
                        localStorage.locale = this.oCurrentLocale[0].lng_code
                    }
                })    
            },
            isLocalhost()   {

                let isLocalhost = window.location.href.toLowerCase().indexOf('http://localhost:')>(-1)
                if(isLocalhost)
                    localStorage.isLocalhost = 1
                else
                    localStorage.isLocalhost = 0
            
                return isLocalhost
            },
            setRestApi()    {

                let isLocalhost = this.isLocalhost()
                if(isLocalhost) {

                    localStorage.restAPI = this.oSet['url-rest-api'].local

                } else    
                    if(window.location.href.toLowerCase().indexOf('dev-app')>(-1))
                        localStorage.restAPI = this.oSet['url-rest-api'].tester
                    else
                        localStorage.restAPI = this.oSet['url-rest-api'].project

                return localStorage.restAPI
            },
            mountedAfterSecurity()  {
                
                let isLocalhost = this.isLocalhost()
                if(isLocalhost) {

                    localStorage.projectName = this.oSet['project-prefix'].local
                }   else

                    if(window.location.href.toLowerCase().indexOf('dev-app')>(-1))
                        localStorage.projectName = this.oSet['project-prefix'].tester
                    else
                        localStorage.projectName = this.oSet['project-prefix'].project

                localStorage.net    =   this.oSet.project
                localStorage.gate   =   this.oSet.gate

                if(parseInt(this.oSet['only-basic-name'])===1)
                    localStorage.projectName = this.oSet['project-prefix'].project
                
                this.PROC.readChargersForDebugger('App.vue')

                this.comeBackEvents_app(1)
                this.ASYNC.chargerDataRead()
                
                this.rerenderTechInfo  =   new Date()                                                    

            },
            createHeavenlyGate()   {

                //xxxxxxxxxxxxxxxxxx
                //let isLocalhost = window.location.href.toLowerCase().indexOf('http://localhost:')>(-1)
                //let netHeavenlyGate = this.URL.getParameterFromURL('net-heavenly-gate')
                let netHeavenlyGate = localStorage.getParameterFromURL

                console.log('TU SOM')
                console.log(netHeavenlyGate)

                if(netHeavenlyGate.trim().length===0 )   {

                    let isAdminIP = false
                    for(let IP of this.oCurrentIP.oAdminsIP)   
                        if(this.oCurrentIP['current-ip'].trim()===IP['admin-IP'].trim())   {
                            isAdminIP = true
                            break
                        }

                    netHeavenlyGate = this.oSet['heavenly-gate'].tester
                    if(isAdminIP) 
                        netHeavenlyGate = this.oSet['heavenly-gate'].developer

                        /*
                    setTimeout(() => {
                        let oTechInfo = this.$store.state.oTechInfo
                        console.log(oTechInfo)                        
                    }, 10000);
                    */

                    localStorage.netHeavenlyGate    =   netHeavenlyGate

                } else {

                    localStorage.netHeavenlyGate    =   localStorage.getParameterFromURL
                }


                //xxxx
                //this.serialNo                   =   netHeavenlyGate
                //this.$store.commit('serialNoToVuex')
                //this.$store.state.serialNo      =   netHeavenlyGate

                this.PROC.getRestartURL('App.vue', localStorage.netHeavenlyGate) 
            },

            okDisplay() {return screen.width > 799 && screen.height > 599},

            comeBackEvents_app()   {    //param
                this.$router.push('/')
            },

            restartComeBack() {

                if(parseInt(localStorage.isActiveApp)===0)  return
                this.isDatachange = 1

                clearInterval(this.controlInterval)
                clearInterval(this.globalInterval) 
                this.controlInterval = null
                this.globalInterval = null

                //this.controlInterval =  setInterval(() => this.ASYNC.chargerDataRead(), localStorage.devControlInt)
                //this.globalInterval  =  setInterval(() => this.comeBackEvents_app(2), localStorage.devGlobalInt)                

            },
            getUseAPI(restApi)  {

                //7https://local-api.econec.tech

                let aRestApi = restApi.split('/')
                return aRestApi[aRestApi.length-1].split('.')[0]
            },
        },
        mounted () {

            localStorage.getParameterFromURL = this.URL.getParameterFromURL('net-heavenly-gate')
            console.log(localStorage.getParameterFromURL)

            this.PROC.getCurrentIP()
            this.PROC.getProjectSetup()
            
            this.$store.commit('contentConsoleToVuex', '')     

            this.DBG.con(window.location.href, 'App.vue', 537, false)
            
            if(window.location.href.indexOf('notAllowAccess')===(-1)) {

                //console.log(this.URL.getParameterFromURL(setApp.gate))

                let netHeavenlyGate 
                let isLocalhost = window.location.href.toLowerCase().indexOf('http://localhost:')>(-1)
                if(isLocalhost && this.URL.getParameterFromURL(setApp.gate).trim().length===0)  {

                    netHeavenlyGate = localStorage.netHeavenlyGate
                    this.displaySimulator = 1

                } else {
                    netHeavenlyGate = this.URL.getParameterFromURL(setApp.gate)
                }

                this.PROC.sendCentralServerApp(     netHeavenlyGate,
                                                    isLocalhost ? 1 : 0)                    
                    

                //this.PROC.controlNetHeavenlyGate(this.URL.getParameterFromURL(setApp.gate))
                this.PROC.controlNetHeavenlyGate(netHeavenlyGate)
                
                localStorage.devControlInt  =   60000
                localStorage.devGlobalInt   =   60000

                localStorage.madeIn = '30.05.23 05:00'
            
                localStorage.restAPI = setApp['rest-api-app'] 

                this.PROC.getBasicSecurity() 
                

                //console.log(localStorage.startDebugger)
                if(typeof   localStorage.startDebugger !=='undefined') {

                    //console.log('INN')

                    this.isAdminIP      =   localStorage.isAdminIP
                    this.adminIP        =   localStorage.adminIP
                    this.currentIP      =   localStorage.currentIP
                    this.serialNo       =   localStorage.serialNo
                    this.axiosPath      =   localStorage.axiosPath
                    this.startDebugger  =   localStorage.startDebugger
                    this.rerenderTechInfo = new Date()
                }

                //---------------------------------------------------
                localStorage.timeStamp      =   new Date()

                
                if(!isLocalhost)  {
                    let URL = ''
                    if(window.location.href.trim().toLowerCase().indexOf('https:')!==0) {

                        let aURL = window.location.href.split('/')
                        let comma = ''
                        for(let i=0;i<aURL.length;++i)  {
                            if(i===0) {URL += 'https:'}   else  {URL += comma + aURL[i]}       
                            comma = '/'        
                        }
                        window.location.href = URL + '?&timestamp=' + localStorage.timeStamp
                    }
                } 
            }

        },
    }

</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";


$smallDisplayMargin:v-bind(smallDisplayMargin);
.small-display  {
    margin:$smallDisplayMargin auto 0 auto;
    color:#cc0000;
    font-family: tahoma;
    font-size:2em;
    font-weight: 600;
    text-align: center;
    width:90%;
    line-height: 50px;
}

#async-data-read-first  {
    clear: both;
    float: left;
    position: absolute;
    top:15px;
    z-index:10000;
    border:solid 0px lime;
    //background-color: #3189c9;
    //opacity: .8;    
    color:#ffc800; //$econec-orange
    line-height: 50px;
    font-size:1.4em;
    font-weight:600;
    width:100%;
    height: 60px;
    text-align: center;
}

#async-data-read  {
    clear: both;
    float: left;
    position: absolute;
    z-index:10000;
    border:solid 0px red;
    color:#ffc800; //$econec-orange
    line-height: 50px;
    font-size:1.4em;
    font-weight:600;    
    width:100%;
    height:160px;
    text-align: center;
    &.setUp  {
        top:0px;
        background-color:transparent !important; 
        opacity: 1;
    }
    &.setDown  {
        top:770px;
        background-color: #042f74; 
        opacity: .9;
    }    
}

#async-data-progress    {
    clear: both;
    float: left;
    position: absolute;
    top:1px;
    z-index:10050;
    background-color: #ffc800;   //$econec-orange;
    width:100%;
    height: 5px;
    animation: renderAsyncData 15s ;    //infinite
}

@keyframes  renderAsyncData {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }    
}

.next   {
    height:30px;
    line-height:30px;
    font-size: 1em;
    background-color:$econec-orange;
    color:#fff;
    cursor: pointer;
}

</style>
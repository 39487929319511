export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["katalánčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ad"])},
  "helper": {
    "do-you-need-advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessites consell?"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El carregador està inactiu o està en servei"])},
  "is-excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualment, l'administrador ha desactivat l'aplicació"])},
  "async-read-data-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espereu mentre l'aplicació carrega les dades; actualitza el magatzem de dades."])},
  "async-read-data-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es recomana revisar tota l'aplicació també per comprovar la seva funcionalitat,"])},
  "async-read-data-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ako aj z dôvodu, aby sa do dátového skladu načítal úplný obsah aplikácie aj s obrazovkami."])},
  "async-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estic buscant un carregador per a la meva bicicleta elèctrica - almenys 3 caràcters"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'aplicació us pot trobar un mòdul de càrrega compatible amb la marca de la vostra màquina"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'aplicació comença a cercar un mòdul de càrrega compatible només després d'introduir almenys tres caràcters"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La màquina elèctrica que busqueu actualment no es troba al catàleg d'aplicacions"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Més informació a la pàgina WEB"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La previsió meteorològica no està disponible actualment !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previsió del temps per hores"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcció del vent"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La pluja"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocitat del vent"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafages de vent"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressió de l'aire"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevant"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sortida del sol"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posta de sol"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assolellat"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mig clar"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ennuvolat"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gairebé ennuvolat"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completament ennuvolat"])}
  },
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESQUENA"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAPA"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDIOMES"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL TEMPS"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGIÓ"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONNECTOR"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AJUDA"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menú de l'aplicació"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquesta part de l'aplicació no està disponible temporalment"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualment, el carregador no admet WIFI compartit"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per a la seva funcionalitat, l'aplicació requereix una pantalla amb una resolució mínima: amplada 800 i alçada 600 píxels"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diumenge"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dilluns"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dimarts"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dimecres"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dijous"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divendres"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dissabte"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gener"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["febrer"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["març"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abril"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maig"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juny"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juliol"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agost"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setembre"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octubre"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de novembre"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desembre"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El propietari del carregador va decidir no compartir la xarxa WIFI pública per motius tècnics"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi ha disponible una xarxa WIFI compartida a prop del carregador"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iniciar Sessió"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrasenya"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho sentim, estem preparant el contingut."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho sentim,~però el mapa d'orientació d'aquest carregador no està disponible en aquest moment"])}
  }
}
export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["írčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ie"])},
  "helper": {
    "do-you-need-advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An bhfuil comhairle uait ?"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tá an charger neamhghníomhach nó i mbun seirbhíse"])},
  "is-excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tá an feidhmchlár díchumasaithe ag an riarthóir faoi láthair"])},
  "async-read-data-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan le do thoil agus an feidhmchlár ag lódáil na sonraí - déanann sé a stór sonraí a nuashonrú."])},
  "async-read-data-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moltar dul tríd an bhfeidhmchlár iomlán freisin chun a fheidhmiúlacht a sheiceáil,"])},
  "async-read-data-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chomh maith leis an gcúis go bhfuil ábhar iomlán an iarratais, lena n-áirítear na scáileáin, luchtaithe isteach sa stóras sonraí."])},
  "async-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tá mé ag lorg charger do mo rothar leictreach - ar a laghad 3 carachtair"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is féidir leis an bhfeidhmchlár modúl muirir a aimsiú duit atá comhoiriúnach le branda do mheaisín"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ní thosaíonn an t-iarratas ag cuardach modúl muirir comhoiriúnach ach amháin tar éis trí charachtar ar a laghad a chur isteach"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níl an meaisín leictreach atá á lorg agat le fáil i gcatalóg na bhfeidhmchlár faoi láthair"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuilleadh eolais ar an leathanach WEB"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níl réamhaisnéis na haimsire ar fáil faoi láthair !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réamhaisnéis na haimsire in aghaidh na huaire"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treo na gaoithe"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An bháisteach"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luas na gaoithe"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séideán gaoithe"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brú aeir"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ag cur sneachta"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["éirí na gréine"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["luí na gréine"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grianmhar"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leath soiléir"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scamallach"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beagnach scamallach"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go hiomlán scamallach"])}
  },
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR AIS"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LÉARSCÁIL"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEANGA"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AN AIMSIR"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RÉIGIÚN EOLAIS"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceanglóir"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CABHRÚ"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roghchlár feidhmchlár"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níl an chuid seo den fheidhmchlár ar fáil go sealadach"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ní thacaíonn an charger le WIFI roinnte faoi láthair"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chun a fheidhmiúlacht, éilíonn an feidhmchlár taispeáint le taifeach íosta: leithead 800 agus airde 600 picteilín"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domhnach"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dé Luain"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dé Máirt"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dé Céadaoin"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déardaoin"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dé hAoine"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dé Sathairn"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eanáir"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feabhra"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Márta"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aibreán"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bealtaine"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meitheamh"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iúil"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lúnasa"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meán Fómhair"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deireadh Fómhair"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samhain"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nollaig"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinn úinéir an charger gan an líonra WIFI poiblí a roinnt ar chúiseanna teicniúla"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tá líonra comhroinnte WIFI ar fáil in aice leis an charger"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logáil isteach"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasfhocal"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faraoir, táimid ag ullmhú an t-ábhar."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tá brón orainn,~ach níl an léarscáil treoshuímh don charger seo ar fáil faoi láthair"])}
  }
}
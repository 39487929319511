export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["albánčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al"])},
  "helper": {
    "do-you-need-advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keni nevojë për këshilla?"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karikuesi është joaktiv ose në shërbim"])},
  "is-excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacioni aktualisht është i çaktivizuar nga administratori"])},
  "async-read-data-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ju lutemi prisni derisa aplikacioni të ngarkojë të dhënat - ai përditëson dyqanin e tij të të dhënave."])},
  "async-read-data-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekomandohet të kaloni të gjithë aplikacionin gjithashtu për të kontrolluar funksionalitetin e tij,"])},
  "async-read-data-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si dhe për arsye se përmbajtja e plotë e aplikacionit, duke përfshirë ekranet, ngarkohet në magazinën e të dhënave."])},
  "async-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unë jam duke kërkuar për një karikues për biçikletën time elektrike - të paktën 3 karaktere"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacioni mund t'ju gjejë një modul karikimi të pajtueshëm me markën e makinës suaj"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacioni fillon të kërkojë për një modul karikimi të pajtueshëm vetëm pasi të keni futur të paktën tre karaktere"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makina elektrike që po kërkoni nuk gjendet aktualisht në katalogun e aplikacionit"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Më shumë informacion në faqen WEB"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parashikimi i motit aktualisht nuk është i disponueshëm !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parashikimi i motit për orë"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drejtimi i eres"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shiu"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shpejtesia e eres"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shpërthime të erës"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presioni i ajrit"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bie borë"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lindja e diellit"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perëndimi i diellit"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["me diell"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gjysmë i qartë"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["me re"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pothuajse me re"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plotësisht me re"])}
  },
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRAPA"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HARTA"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GJUHËT"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOTI"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAJONI INFO"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONKEKTOR"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NDIHMË"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menuja e aplikacionit"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kjo pjesë e aplikacionit është përkohësisht e padisponueshme"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karikuesi aktualisht nuk e mbështet WIFI të përbashkët"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Për funksionalitetin e tij, aplikacioni kërkon një ekran me një rezolucion minimal: gjerësi 800 dhe lartësi 600 piksele"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["të dielën"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e hënë"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e martë"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e mërkurë"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e enjte"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e premte"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e shtunë"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["janar"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shkurt"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marsh"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prill"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mund"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qershor"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["korrik"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gusht"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shtator"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tetor"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nëntor"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dhjetor"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronari i karikuesit vendosi të mos ndajë rrjetin publik WIFI për arsye teknike"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Një rrjet i përbashkët WIFI ofrohet pranë karikuesit"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikohu"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjalëkalimi"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na vjen keq, ne po përgatisim përmbajtjen."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na vjen keq,~por harta orientuese për këtë karikues nuk disponohet për momentin"])}
  }
}

<template>

    <div    id="view-console-in-App"
            class="console-space display-none">

        <div class="console-space-top">
            <!--i18n-->
            <div    class="console-space-top-first" 
                    @click="setUpWin()"
                    title="Posunúť túto konzolu vyššie">

                <img    :src=" pathIcons + 'white/square-up-econec-white.svg'" 
                        class="console-space-top-first-icon"
                        alt=""  />
            </div>                        
            <div class="console-space-top-head" title="Zatvoriť túto konzolu"> 
                <!--i18n-->
                <ul><li>Konzola aplikácie na vzdialenom bode pre účely ladenia</li></ul> 
            </div>                
            <!--i18n-->
            <div    class="console-space-top-last" 
                    @click="DBG.closeDbg()"    
                    title="Zatvoriť túto konzolu"> 

                <div class="console-space-top-last-icon">&#x2716;</div>
                
            </div>                                    
        </div>

        <div    id="console-content"   class="console-space-into" />

        <div class="console-space-bottom">
            <!--i18n-->
            <div    class="console-space-bottom-first" 
                    title="Posunúť túto konzolu vyššie"> 

                <img    :src=" pathIcons + 'white/square-down-econec-white.svg'" 
                        @click="setDownWin()"
                        class="console-space-bottom-first-icon"
                        alt=""  />
            </div>                                    
            <!--i18n-->
            <div    class="console-space-bottom-last" 
                    title="Dvojitým potvrdením vymazať aktuálny obsah tejto konzoly"> 

                <img    :src=" pathIcons + 'white/cleaning-white.svg'" 
                        @dblclick="DBG.clearCon()"
                        class="console-space-bottom-first-icon" 
                        alt=""  />
            </div>
            <!--i18n-->
            <div class="console-space-bottom-after" title="Dvojitým potvrdením zaslať obsah konzoly administrátorovi"> 
                <img    :src=" pathIcons + 'white/post-mail-white.svg'" 
                        class="console-space-bottom-after-icon"
                        @dblclick="DBG.mailForDeveloper()"
                        alt=""  />
            </div>            
        </div>
    </div>
</template>

<script>

    import  DBG                                         from '@/assets/modules/Debugger'

    export default {
        data() {
            return {

                DBG:                                    new DBG(this),

                oSet:                                   [],
                pathIcons:                              '',

                width:                                  this.propWidth.toString() + 'px',
                height:                                 this.propHeight.toString() + 'px',
                top:                                    (((1024 - parseInt(this.propHeight)) / 2)).toString() + 'px',
                topNumber:                              (((1024 - parseInt(this.propHeight)) / 2)),
                left:                                   ((1280 - parseInt(this.propWidth)) / 2).toString() + 'px',
                topHeight:                              this.propTopHeight.toString() + 'px',
                iconSize:                               (this.propTopHeight - 10).toString() + 'px',
                consoleHeight:                          (((this.propHeight - this.propTopHeight) - this.propBottomHeight)-2).toString() + 'px',
            }
        },
        props: {
            propWidth: {
                type: Number,
                default: 700,
            },
            propHeight: {
                type: Number,
                default: 500,
            },        
            propTopHeight:  {
                type: Number,
                default: 40,
            },    
            propBottomHeight:  {
                type: Number,
                default: 40,
            },                
        },
        methods: {
            setUpWin() {
                
                let setTopConsole =  parseInt(this.$store.state.setTopConsole)
                if((setTopConsole-20)>=0)    {
                    setTopConsole -= 10
                    this.top = setTopConsole.toString() + 'px'
                    this.$store.commit('setTopConsoleToVuex', parseInt(setTopConsole))
                }
            },

            setDownWin() {
                console.log('setDownWin()   ') 
                let setTopConsole =  parseInt(this.$store.state.setTopConsole)
                console.log((setTopConsole+this.height)+20)
                if(((parseInt(setTopConsole) + parseInt(this.height))+20)<=1000)    {
                    setTopConsole += 10
                    this.top = setTopConsole.toString() + 'px'
                    this.$store.commit('setTopConsoleToVuex', parseInt(setTopConsole))
                }
            },            
        },
        mounted () {

            this.$store.commit('setTopConsoleToVuex', parseInt(this.topNumber))

            setTimeout(() => {
                this.oSet       =   this.$store.state.oSet
                this.pathIcons  =   this.$store.state.oSet.url.icons
            }, 1000);
            
        },
    }
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";

.console  {
    &-space {
        position: absolute;
        z-index: 500000;
        top: v-bind(top);
        left:v-bind(left);
        width: v-bind(width);
        height: v-bind(height);    
        background-color: #cc0000;
        opacity: .9;
        border:solid 1px #fff;
        box-shadow: 6px 6px 10px #003b6b;
        &-top  {
            position:relative;
            clear: both;
            float: left;
            background-color: #000;
            color:#fff;
            width:100%;
            height:v-bind(topHeight);
            &-first {
                clear: both;
                float: left;            
                width:v-bind(topHeight);
                height:v-bind(topHeight);
                color:#fff;            
                text-align: center;
                &-icon  {
                    clear: both;
                    margin: 5px 0 0 0;
                    width:v-bind(iconSize);
                    height:v-bind(iconSize);
                    cursor: pointer;
                }
            }
            &-last  {
                float: right;                            
                width:v-bind(topHeight);
                height:v-bind(topHeight);                
                height:v-bind(topHeight);
                color:#fff;            
                text-align: center;                

                &-icon  {
                    margin:2.5px auto 0 auto;
                    width:v-bind(iconSize);
                    height:v-bind(iconSize);
                    font-size:1.3em;
                    background-color: #fff;
                    color: #000;
                    text-align: center;
                    cursor: pointer;
                }
            }
            &-head  {
                float:left;
                border:solid 0px red;
                height:v-bind(topHeight);
                line-height: v-bind(topHeight);
                font-size: 1.1em;
                font-weight: 600;
                width: 80%;
            }
        }
        &-into    {
            clear:both;
            float: left;
            height:v-bind(consoleHeight);
            width:100%; 
            font-size: 1.1em;
            line-height: 1.1em;
            font-weight: 600em;            
            padding:0px 20px;
            color:#fff;
            overflow-y: scroll;
        }        
        &-bottom  {
            position:relative;
            clear: both;
            float: left;
            background-color: #000;
            color:#fff;
            width:100%;
            height:v-bind(topHeight);
            &-first {
                clear: both;
                float: left;            
                width:v-bind(bottomHeight);
                height:v-bind(bottonHeight);
                color:#fff;            
                text-align: center;
                &-icon  {
                    border:solid 0px red;
                    margin: 5px 0 0 5px;
                    width:v-bind(iconSize);
                    height:v-bind(iconSize);
                    cursor: pointer;
                }
            }            

            &-last  {
                margin: 0 10px 0 0;
                float: right;                            
                width:v-bind(topHeight);
                height:v-bind(topHeight);                
                height:v-bind(topHeight);
                color:#fff;            
                text-align: center;                

                &-icon  {
                    margin:2.5px auto 0 auto;
                    width:v-bind(iconSize);
                    height:v-bind(iconSize);
                    font-size:1.3em;
                    background-color: #000;
                    color: #000;
                    text-align: center;
                    cursor: pointer;
                }
            }

            &-after  {
                margin: 0 10px 0 0;
                float: right;                            
                width:v-bind(topHeight);
                height:v-bind(topHeight);                
                height:v-bind(topHeight);
                color:#fff;            
                text-align: center;                

                &-icon  {
                    margin:6px auto 0 auto;
                    width:v-bind(iconSize);
                    height:v-bind(iconSize);
                    font-size:1.3em;
                    background-color: #000;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }
}


#console-content::-webkit-scrollbar {
    width: 10px;
}
#console-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px #fff; 
    border-radius: 6px;
}
#console-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px yellow; 
    cursor: pointer;
}


</style>

import axios    from    "axios"
import DT       from    '@/assets/modules/DateTime'
import OBJ      from    '@/assets/modules/OBJMethodsClass';

export default class Specials {

constructor(App) {

    this.App            =   App
    this.DT             =   new DT(this.App)
    this.OBJ            =   new OBJ(this.App)

    this.notDetected    =   'Not detected'
}


formData = (axiosPath='')   =>  {

    let formData = new FormData()

    if(axiosPath.trim().length>0)
        formData.append('axios', axiosPath)
    else
        formData.append('axios', '')

    //console.log('= ' + localStorage.netHeavenlyGate+ ' =')
    //console.log('= ' + axiosPath+ ' =')
    //console.log('================================')

    //formData.append('from-app',             'is')
    formData.append('from-app',             localStorage['from-app'])
    
    formData.append('security-key',         window.location.href)

    formData.append('net',                  localStorage.projectName)

    formData.append('net-heavenly-gate',    localStorage.netHeavenlyGate )
      
    //formData.append('api',                  localStorage.URLRestApi)   
    formData.append('api',                  localStorage.restAPI)   
    //localStorage.restAPI

    return formData
} 

sendProcessAboutError   =   (   isType,
                                title,
                                axios_,
                                oFormData, 
                                oResponseData)  =>  {


    console.log(axios_)

    //let axiosIn = ''
    let command, fromApp, securityKey, project, netHeavenlyGate, useAPI, otherProtocol  //url, 
    for (var pair of oFormData.entries()) {
        console.log(pair[0]+ ' :: ' + pair[1]); 
        if(pair[0]==='command')             command = pair[1]
        if(pair[0]==='from-app')            fromApp = pair[1]
        if(pair[0]==='security-key')        securityKey = pair[1]
        if(pair[0]==='net')                 project = pair[1]
        if(pair[0]==='net-heavenly-gate')   netHeavenlyGate = pair[1]
        //if(pair[0]==='api')                 url = pair[1]
        if(pair[0]==='useAPI')              useAPI = pair[1]
        if(pair[0]==='other-protocol')      otherProtocol = pair[1]
    }                                 
    
    let error = ''
    if(typeof oResponseData.error === 'string')
        error   =   oResponseData.error
    else
        error   =   title

    let fields  =   '{'
        fields  +=  '"errorText":"'+error+'", '
        fields  +=  '"axios":"'+axios_+'", '
        fields  +=  '"command":"'+command+'", '
        fields  +=  '"fromApp":"'+fromApp+'", '
        fields  +=  '"securityKey":"'+securityKey+'", '
        fields  +=  '"project":"'+project+'", '
        fields  +=  '"netHeavenlyGate":"'+netHeavenlyGate+'", '
        fields  +=  '"otherProtocol":"'+otherProtocol+'", '
        fields  +=  '"useAPI":"'+useAPI+'", '
        fields  +=  '"url":"'+window.location.href+'"'
        fields  +=  '}'

    
    let formData = this.formData(axios_)  
    formData.append('command',  'proc-sendProcessAboutError')
    formData.append('fields',   fields)

    //console.log(fields)

    axios.post(localStorage.restAPI, formData).then(response => {

        if(typeof response.data !== 'object')   {

            console.error(axios_)
            console.error(localStorage.restAPI)
            console.error(typeof response.data)
            console.error(response.data.trim().length)
            console.error(response.data)
        }
    })        
    //}
}

isErrorResponseData =   (   axios_='', 
                            oFormData, 
                            response, 
                            requiredObject=false, 
                            mustBeGreaterThanZero=false,
                            simulatingError=0)  =>  { 
    
    let returnError = 0, errorText = '', oData = []

    if( (typeof response.data !== 'object' && requiredObject) || 
        parseInt(simulatingError)===1)   {

        errorText = 'Odpoveď z REST API nie je typu = object =, aj keď to systém vyžaduje'
        console.log(errorText)
        console.log(axios_)
        console.log(oFormData)
        console.log(oData)   
        console.log(response.data)         

        
        this.sendProcessAboutError( 1, errorText,
                                    axios_,
                                    oFormData, 
                                    response.data)

        console.log('ERR :: 1')
        if(parseInt(simulatingError)===0) returnError = 1
    }

    if( typeof response.data === 'object' && mustBeGreaterThanZero   ||
        parseInt(simulatingError)===2)   {

        oData = response.data
        if(parseInt(simulatingError)===2)   {
            let sError  =   '{'
            sError      +=  '"error":"K volaniu procedúry nad REST API aktuálne neexistuje oprávnenie ! ", '
            sError      +=  '"axios":"App.vue / dbProc.App.readChargersForDebugger()", '
            sError      +=  '"hostAddr":"178-143-45-184.static.orange.sk", '
            sError      +=  '"id":"37", ' 
            sError      +=  '"ip": "178.143.45.184", ' 
            sError      +=  '"line": "65", ' 
            sError      +=  '"sector":"SQLProcedures"}' 
    
            oData = JSON.parse(sError)            
        }

        if( this.OBJ.count(oData)===0 ||
            parseInt(simulatingError)===2)   {

            errorText = 'Rozhranie REST API vrátilo prázdny objekt pri axios požiadavke, pri ktorej je prázdny objekt neprípustný'
            console.log(errorText)
            console.log(axios_)
            console.log(oFormData)
            console.log(oData)

            this.sendProcessAboutError( 2, errorText,
                                        axios_,
                                        oFormData, 
                                        oData)

            console.log('ERR :: 2')
            if(parseInt(simulatingError)===0) returnError = 2
        }        
    }

    if( typeof response.data.error === 'string'  ||
        parseInt(simulatingError)===3) {


        console.log(response.data)
        console.log(response.data.error)
        console.log(axios_)
        console.log(oFormData)
        console.log(oData)

        errorText = 'REST API pri odštartovaní požadovaného procesu zistilo chybu pri spracovaní požiadavky, a zapísalo ju do chybovej databázy'
        this.sendProcessAboutError( 3, response.data.error,
                                    axios_,
                                    oFormData, 
                                    oData)

        console.log('ERR :: 3')
        if(parseInt(simulatingError)===0) returnError = 3
    }

    if(parseInt(returnError)>0) return true

    return false
}

axiosErrorCatch =   (callFromMethod, errorMsg, oFormData, axios_)  =>  {

    this.sendProcessAboutError( 0, errorMsg,
                                axios_,
                                oFormData, 
                                [])

}

createFormDataForSend   =   (oFormData)  =>  {

    let formString, formForSend = '', comma = ''        
    
    for (let formRecord of oFormData.entries()) {

        if(formRecord==='fields')   {
            formString      =   formRecord[1];

            formString      =   formString.replaceAll('{', '')
            formString      =   formString.replaceAll('}', '')
            formString      =   formString.replaceAll('"', '')
            formString      =   formString.replaceAll(' ', '')
            formString      =   formString.replaceAll(':', '♥')    //3
            formString      =   formString.replaceAll(',', '♠')    //6                

            formRecord[1]   =   formString
        }
        formForSend += comma + formRecord[0] + '~' + formRecord[1]
        comma = '|'            
    }

    return formForSend
}
getTechInfo =   ()   =>  {  //oCurrentCharger=[]
 

    let sJSON = '', oTechInfo = []
    if(typeof localStorage.serialNo==='string') {
 
        if(localStorage.serialNo.trim().length>15)  {
 
            sJSON    +=  '{' 
            sJSON    +=  '"isAdminIP":"'+localStorage.isAdminIP+'",' 
            sJSON    +=  '"adminIP":"'+localStorage.adminIP+'",' 
            sJSON    +=  '"currentIP":"'+localStorage.currentIP+'",' 
            sJSON    +=  '"currentHostAddr":"'+localStorage.currentHostAddr+'",' 
            sJSON    +=  '"serialNo":"'+localStorage.serialNo+'",' 
            sJSON    +=  '"axiosPath":"'+localStorage.axiosPath+'",' 
            sJSON    +=  '"debugger":"'+localStorage.startDebugger+'"' 
            sJSON    +=  '}' 

            oTechInfo   =   JSON.parse(sJSON)
            this.App.$store.commit('oTechInfoToVuex', oTechInfo)

            return oTechInfo

        }
    }

    return oTechInfo
   
}

}


//  this.$store.commit('[...]ToVuex', [...])
//  this.$store.state.[...]

import { createStore } from 'vuex'

export default createStore({
  state: {

    setTopConsole:                      0,
    contentConsole:                     '',

    firstAsyncReadData:                 0,
    aHandShots:                         [],

    oCurrentIP:                         [],

    oSet:                               [],

    oCurrentCharger:                    [],
    oChargerConnects:                   [],
    oFlagsFromThisCharger:              [],
    oSliders:                           [],
    oHelpContent:                       [],
    oTechInfo:                          [],
    oLastDataCharger:                   [],
    oAllChargers:                       [],

  

    //Content Management systems
    oRegionManage:                      [],
    oRegionInLines:                     [],
    oRegionInterTexts:                  [],
    //===========================

    controlObjectSliders:               0,

    asyncDataRead:                      0,

    promoVideo:                         '',

    //------------ WEATHER --------------------------------------
    predictAladinDate:                  '',
    oPredictAladin_0:                   [],
    oPredictAladin_1:                   [],
    oPredictAladin_2:                   [],

    oGetAllow:                          [],
    oGetCloudy:                         [],    

    /*
    //CMS 
    oRegionManageCMS:         [],    
    oRegionLinesCMS:          [],    
    oRegionInterTextsCMS:     [],    
    */

  },

  getters: {},

  mutations: {

    setTopConsoleToVuex(state, setTopConsole) {
      state.setTopConsole = setTopConsole
    },            
    contentConsoleToVuex(state, contentConsole) {
      state.contentConsole = contentConsole
    },                


    aHandShotsToVuex(state, aHandShots) {
      state.aHandShots = aHandShots
    },        
    
    firstAsyncReadDataToVuex(state, firstAsyncReadData) {
      state.firstAsyncReadData = firstAsyncReadData
    },    

    oCurrentIPToVuex(state, oCurrentIP) {
      state.oCurrentIP = oCurrentIP
    },
    
    oSetToVuex(state, oSet) {
      state.oSet = oSet
    },          

    oCurrentChargerToVuex(state, oCurrentCharger) {
      state.oCurrentCharger = oCurrentCharger
    },      

    oChargerConnectsToVuex(state, oChargerConnects) {
      state.oChargerConnects = oChargerConnects
    },          

    oFlagsFromThisChargerToVuex(state, oFlagsFromThisCharger) {
      state.oFlagsFromThisCharger = oFlagsFromThisCharger
    },      
    
    oSlidersToVuex(state, oSliders) {
      state.oSliders = oSliders
    },          

    oHelpContentToVuex(state, oHelpContent) {
      state.oHelpContent = oHelpContent
    },          

    controlObjectSlidersToVuex(state, controlObjectSliders) {
      state.controlObjectSliders = controlObjectSliders
    },          

    asyncDataReadToVuex(state, asyncDataRead) {
      state.asyncDataRead = asyncDataRead
    },          
    
    oTechInfoToVuex(state, oTechInfo) {
      state.oTechInfo = oTechInfo
    },      
    oLastDataChargerToVuex(state, oLastDataCharger) {
      state.oLastDataCharger = oLastDataCharger
    },          
    oContentManageToVuex(state, oContentManage) {
      state.oContentManage   = oContentManage
    },        
    

    //Content Management systems
    oRegionManageToVuex(state, oRegionManage) {
      state.oRegionManage   = oRegionManage
    },
    oRegionInLinesToVuex(state, oRegionInLines) {
      state.oRegionInLines   = oRegionInLines
    },
    oRegionInterTextsToVuex(state, oRegionInterTexts) {
      state.oRegionInterTexts   = oRegionInterTexts
    },
    //===========================

    
    //All chrgers
    oAllChargersToVuex(state, oAllChargers) {
      state.oAllChargers   = oAllChargers
    },


    //VIDEO
    promoVideoToVuex(state, promoVideo) {
      state.promoVideo   = promoVideo
    },  
   
    //------------ WEATHER --------------------------------------
    predictAladinDateToVuex(state, predictAladinDate) {
      state.predictAladinDate   = predictAladinDate
    },  

    oPredictAladin_0ToVuex(state, oPredictAladin_0) {
      state.oPredictAladin_0   = oPredictAladin_0
    },

    oPredictAladin_1ToVuex(state, oPredictAladin_1) {
      state.oPredictAladin_1   = oPredictAladin_1
    },

    oPredictAladin_2ToVuex(state, oPredictAladin_2) {
      state.oPredictAladin_2   = oPredictAladin_2
    },    

    oGetAllowToVuex(state, oGetAllow) {
      state.oGetAllow   = oGetAllow
    },    

    oGetCloudyToVuex(state, oGetCloudy) {
      state.oGetCloudy   = oGetCloudy
    },      

    /*
    //CMS 
    oRegionManageCMSToVuex(state, oRegionManageCMS) {
      state.oRegionManageCMS   = oRegionManageCMS
    },      
    oRegionLinesCMSToVuex(state, oRegionLinesCMS) {
      state.oRegionLinesCMS   = oRegionLinesCMS
    },          
    oRegionInterTextsCMSToVuex(state, oRegionInterTextsCMS) {
      state.oRegionInterTextsCMS   = oRegionInterTextsCMS
    },
    */              
    
    
  },

  actions: {},

  modules: {}
})
